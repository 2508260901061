var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card',{staticClass:"diagnosis-info-section"},[_c('b-card-title',{staticClass:"text-primary"},[_c('i',{staticClass:"fas fa-donate"}),_c('span',{staticClass:"ml-2"},[_vm._v("Vamos falar sobre sua liberdade financeira.")])]),_c('div',{staticClass:"diagnosis-info-content"},[_c('b-form-row',{staticClass:"align-items-end"},[_c('b-form-group',{staticClass:"col-12 col-lg-6",attrs:{"label":"Qual a renda desejada para a sua liberdade financeira, trabalhar menos ou para a sua aposentadoria?","label-for":"plans-and-dreams-renda-desejada-input"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"plans-and-dreams-renda-desejada-input"},model:{value:(
              _vm.customer.pessoa.pessoa_plano
                .plano_renda_desejada_liberdade_financeira
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_plano
                , "plano_renda_desejada_liberdade_financeira", $$v)},expression:"\n              customer.pessoa.pessoa_plano\n                .plano_renda_desejada_liberdade_financeira\n            "}},'money',_vm.money,false))],1),_c('b-form-group',{staticClass:"col-12 col-lg-6",attrs:{"label":"Com quantos anos gostaria de atingir sua liberdade financeira / trabalhar menos / aposentar?","label-for":"plans-and-dreams-idade-desejada-input"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","id":"plans-and-dreams-renda-desejada-input"},model:{value:(
              _vm.customer.pessoa.pessoa_plano
                .plano_quantos_anos_liberdade_financeira
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_plano
                , "plano_quantos_anos_liberdade_financeira", $$v)},expression:"\n              customer.pessoa.pessoa_plano\n                .plano_quantos_anos_liberdade_financeira\n            "}})],1),_c('b-form-group',{staticClass:"col-12 col-lg-6",attrs:{"label":"Até quantos anos gostaria de garantir esta renda?","label-for":"plans-and-dreams-ate_quando-input"}},[_c('b-form-input',{staticClass:"form-control",attrs:{"type":"number","id":"plans-and-dreams-ate_quando-input"},model:{value:(
              _vm.customer.pessoa.pessoa_plano
                .plano_ate_quantos_anos_liberdade_financeira
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_plano
                , "plano_ate_quantos_anos_liberdade_financeira", $$v)},expression:"\n              customer.pessoa.pessoa_plano\n                .plano_ate_quantos_anos_liberdade_financeira\n            "}})],1)],1),_c('b-form-row',{staticClass:"align-items-end"},[_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"É contribuinte de previdencia oficial ?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(
              _vm.customer.pessoa.pessoa_despesa
                .contribuinte_previdencia_aposentado
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_despesa
                , "contribuinte_previdencia_aposentado", $$v)},expression:"\n              customer.pessoa.pessoa_despesa\n                .contribuinte_previdencia_aposentado\n            "}})],1),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"É atualmente aposentado ou com mais de 65 anos?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(_vm.customer.pessoa.pessoa_despesa.aposentado_maior_65_anos),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_despesa, "aposentado_maior_65_anos", $$v)},expression:"customer.pessoa.pessoa_despesa.aposentado_maior_65_anos"}})],1),(_vm.customer.pessoa.pessoa_despesa.aposentado_maior_65_anos)?_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Qual renda mensal da aposentadoria?"}},[_c('money',_vm._b({staticClass:"form-control",model:{value:(_vm.customer.pessoa.pessoa_despesa.teste),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_despesa, "teste", $$v)},expression:"customer.pessoa.pessoa_despesa.teste"}},'money',_vm.money,false))],1):_vm._e(),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-5",attrs:{"label":"Qual sua expectativa de renda na aposentadoria pelo INSS?","label-for":"expense-expectativa_renda_aposentadoria_inss-input"}},[_c('money',_vm._b({staticClass:"form-control",attrs:{"id":"expense-expectativa_renda_aposentadoria_inss-input"},model:{value:(
              _vm.customer.pessoa.pessoa_despesa.expectativa_renda_aposentadoria
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_despesa, "expectativa_renda_aposentadoria", $$v)},expression:"\n              customer.pessoa.pessoa_despesa.expectativa_renda_aposentadoria\n            "}},'money',_vm.money,false))],1)],1),_c('b-form-row',{staticClass:"align-items-end"},[_c('b-form-group',{staticClass:"col-12 col-lg-6",attrs:{"label":"Possui algum investimento em previdência privada?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(
              _vm.customer.pessoa.pessoa_previdencia_privada.possui_investimento
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_previdencia_privada, "possui_investimento", $$v)},expression:"\n              customer.pessoa.pessoa_previdencia_privada.possui_investimento\n            "}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(
            !_vm.customer.pessoa.pessoa_previdencia_privada.possui_investimento
          ),expression:"\n            !customer.pessoa.pessoa_previdencia_privada.possui_investimento\n          "}],staticClass:"col-12 col-lg-6",attrs:{"label":"Já pensou em iniciar?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(
              _vm.customer.pessoa.pessoa_previdencia_privada.pensou_iniciar
            ),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_previdencia_privada, "pensou_iniciar", $$v)},expression:"\n              customer.pessoa.pessoa_previdencia_privada.pensou_iniciar\n            "}})],1)],1)],1)],1),_c('b-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.customer.pessoa.pessoa_previdencia_privada.possui_investimento),expression:"customer.pessoa.pessoa_previdencia_privada.possui_investimento"}],staticClass:"diagnosis-info-section"},[_c('b-card-title',{staticClass:"text-primary"},[_c('i',{staticClass:"fas fa-donate"}),_c('span',{staticClass:"ml-2"},[_vm._v("Como está alocada sua previdência privada.")])]),_c('div',{staticClass:"diagnosis-info-content"},_vm._l((_vm.customer.pessoa
          .pessoa_previdencia_privada.previdencia_fundos),function(pensionFund,index){return _c('b-form-row',{key:index,staticClass:"align-items-end"},[_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Fundo","label-for":("financial-freedom-pension-fund-" + index + "-name-input")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-input',{attrs:{"id":("financial-freedom-pension-fund-" + index + "-name-input"),"aria-describedby":ariaDescribedby,"type":"text"},model:{value:(pensionFund.nome),callback:function ($$v) {_vm.$set(pensionFund, "nome", $$v)},expression:"pensionFund.nome"}})]}}],null,true)}),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"CNPJ","label-for":("financial-freedom-pension-fund-" + index + "-cnpj-input")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###/####-##'),expression:"'##.###.###/####-##'"}],attrs:{"id":("financial-freedom-pension-fund-" + index + "-cnpj-input"),"aria-describedby":ariaDescribedby,"type":"text"},model:{value:(pensionFund.cnpj),callback:function ($$v) {_vm.$set(pensionFund, "cnpj", $$v)},expression:"pensionFund.cnpj"}})]}}],null,true)}),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Tipo do plano","label-for":("financial-freedom-pension-fund-" + index + "-plan-type-input")}},[_c('b-form-select',{attrs:{"id":("financial-freedom-pension-fund-" + index + "-plan-type-input"),"options":_vm.tipoPlanoOptions},model:{value:(pensionFund.tipo_plano),callback:function ($$v) {_vm.$set(pensionFund, "tipo_plano", $$v)},expression:"pensionFund.tipo_plano"}})],1),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Tributação","label-for":("financial-freedom-pension-fund-" + index + "-tributacao-input")}},[_c('b-form-select',{attrs:{"id":("financial-freedom-pension-fund-" + index + "-tributacao-input"),"options":_vm.tributacaoOptions},model:{value:(pensionFund.tributacao),callback:function ($$v) {_vm.$set(pensionFund, "tributacao", $$v)},expression:"pensionFund.tributacao"}})],1),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Saldo atual","label-for":("financial-freedom-pension-fund-" + index + "-valor-atual-input")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"id":("financial-freedom-pension-fund-" + index + "-valor-atual-input"),"aria-describedby":ariaDescribedby},model:{value:(pensionFund.valor_atual),callback:function ($$v) {_vm.$set(pensionFund, "valor_atual", $$v)},expression:"pensionFund.valor_atual"}},'money',_vm.money,false))]}}],null,true)}),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Custo de inventário"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(pensionFund.custo_inventario),callback:function ($$v) {_vm.$set(pensionFund, "custo_inventario", $$v)},expression:"pensionFund.custo_inventario"}}),_c('i',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"bx bx-help-circle",staticStyle:{"font-size":"1.2rem","position":"absolute","left":"140px","top":"-27px"},attrs:{"title":"O montante deste fundo de Previdência pode ser utilizado para o pagamento de custas de inventário?"}})],1),_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Faz aportes regularmente?"}},[_c('b-form-radio-group',{attrs:{"options":_vm.yesOrNotOptions},model:{value:(pensionFund.faz_aportes_regularmente),callback:function ($$v) {_vm.$set(pensionFund, "faz_aportes_regularmente", $$v)},expression:"pensionFund.faz_aportes_regularmente"}})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(pensionFund.faz_aportes_regularmente),expression:"pensionFund.faz_aportes_regularmente"}],staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Qual valor?","label-for":("financial-freedom-pension-fund-" + index + "-valor-aportes-input")},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ariaDescribedby = ref.ariaDescribedby;
return [_c('money',_vm._b({staticClass:"form-control",attrs:{"id":("financial-freedom-pension-fund-" + index + "-valor-aportes-input"),"aria-describedby":ariaDescribedby},model:{value:(pensionFund.valor_aportes),callback:function ($$v) {_vm.$set(pensionFund, "valor_aportes", $$v)},expression:"pensionFund.valor_aportes"}},'money',_vm.money,false))]}}],null,true)}),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(pensionFund.faz_aportes_regularmente),expression:"pensionFund.faz_aportes_regularmente"}],staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Com qual frequencia faz aportes?","label-for":("equity-investments-patrimony-" + index + "-aportes-input")}},[_c('b-form-select',{attrs:{"options":_vm.aportesOptions,"id":("equity-investments-patrimony-" + index + "-aportes-input")},model:{value:(pensionFund.frequencia_aportes),callback:function ($$v) {_vm.$set(pensionFund, "frequencia_aportes", $$v)},expression:"pensionFund.frequencia_aportes"}})],1),(
            pensionFund.faz_aportes_regularmente &&
            pensionFund.frequencia_aportes === 5
          )?_c('b-form-group',{staticClass:"col-12 col-md-6 col-lg-4",attrs:{"label":"Outra frequencia aportes","label-for":("equity-investments-patrimony-" + index + "-aportes-input")}},[_c('b-form-input',{attrs:{"placeholder":"Outros aportes","id":("equity-investments-patrimony-" + index + "-outros-aportes-input")},model:{value:(pensionFund.frequencia_aportes_customizada),callback:function ($$v) {_vm.$set(pensionFund, "frequencia_aportes_customizada", $$v)},expression:"pensionFund.frequencia_aportes_customizada"}})],1):_vm._e(),_c('b-col',{staticClass:"text-right mb-lg-3",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"pill":"","variant":"danger"},on:{"click":function () { return _vm.handleDeletePensionFund(index); }}},[_c('i',{staticClass:"fas fa-trash-alt"})])],1),_c('hr',{staticClass:"col-12 mt-2"})],1)}),1),_c('b-button-group',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function () { return _vm.addPensionFund(); }}},[_vm._v(" ADICIONAR FUNDO ")]),_c('b-button',{attrs:{"variant":"dark-primary"},on:{"click":function () { return _vm.addPensionFund(); }}},[_c('i',{staticClass:"bx bx-plus",staticStyle:{"font-size":"0.95rem"}})])],1)],1),_c('b-card',{staticClass:"diagnosis-info-section"},[_c('p',[_vm._v("Alguma observação?")]),_c('div',{staticClass:"diagnosis-info-content"},[_c('b-form-group',{attrs:{"label-for":"comment-input"}},[_c('b-form-textarea',{attrs:{"id":"financial-freedom-observation-textarea","rows":"5","placeholder":"Observação..."},model:{value:(_vm.customer.pessoa.pessoa_previdencia_privada.observacao),callback:function ($$v) {_vm.$set(_vm.customer.pessoa.pessoa_previdencia_privada, "observacao", $$v)},expression:"customer.pessoa.pessoa_previdencia_privada.observacao"}})],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }