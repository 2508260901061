<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-dollar-sign"></i>
        <span class="ml-2">Renda</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-center">
          <b-form-group
            label="Qual o seu salário/renda bruta mensal?"
            class="col-12 col-md-6 col-lg-6"
            label-for="income-salario-bruto-input"
          >
            <money
              :class="`form-control ${
                errors['pessoa_renda.pessoa_renda_salario_bruto']
                  ? 'is-invalid'
                  : ''
              }`"
              id="income-salario-bruto-input"
              v-bind="money"
              v-model="customer.pessoa.pessoa_renda.pessoa_renda_salario_bruto"
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_renda.pessoa_renda_salario_bruto']"
            >
              {{ errors['pessoa_renda.pessoa_renda_salario_bruto'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual o seu salário/renda liquida mensal?"
            class="col-12 col-md-6 col-lg-6"
            label-for="income-salario-liquido-input"
          >
            <money
              :class="`form-control ${
                errors['pessoa_renda.pessoa_renda_salario_liquido']
                  ? 'is-invalid'
                  : ''
              }`"
              id="income-salario-liquido-input"
              v-bind="money"
              v-model="
                customer.pessoa.pessoa_renda.pessoa_renda_salario_liquido
              "
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_renda.pessoa_renda_salario_liquido']"
            >
              {{
                errors['pessoa_renda.pessoa_renda_salario_liquido'].join(', ')
              }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Possui outra renda mensal?"
            class="col-12 col-md-6 col-lg-3"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_renda.pessoa_renda_possui_renda_mensal
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-col
            cols="12"
            class="mb-3"
            v-show="
              customer.pessoa.pessoa_renda.pessoa_renda_possui_renda_mensal
            "
          >
            <b-form-row
              class="col-12 align-items-end"
              v-for="(monthlyIncome, index) in customer.pessoa.pessoa_renda
                .pessoa_renda_mensais"
              :key="`monthly-${index}`"
            >
              <b-form-group
                label="Qual?"
                class="col-12 col-md-5 col-lg-3"
                :label-for="`income-renda-mensal-${index}-nome-input`"
              >
                <b-form-input
                  :id="`income-renda-mensal-${index}-nome-input`"
                  placeholder="ex: Aluguel/Dividendos"
                  v-model="monthlyIncome.nome"
                />
              </b-form-group>

              <b-form-group
                label="Quanto?"
                class="col-12 col-md-5 col-lg-3"
                :label-for="`income-renda-mensal-${index}-valor-input`"
              >
                <money
                  class="form-control"
                  :id="`income-renda-mensal-${index}-valor-input`"
                  v-bind="money"
                  v-model="monthlyIncome.valor"
                />
              </b-form-group>

              <b-col md="2" lg="6" class="text-right">
                <b-button
                  pill
                  variant="danger"
                  @click="() => handleDeleteMonthlyIncome(index)"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </b-col>
            </b-form-row>

            <b-button-group>
              <b-button @click="() => addMonthlyIncome()" variant="primary">
                ADICIONAR RENDA MENSAL
              </b-button>

              <b-button
                @click="() => addMonthlyIncome()"
                variant="dark-primary"
              >
                <i class="bx bx-plus" style="font-size: 0.95rem"></i>
              </b-button>
            </b-button-group>
          </b-col>

          <hr class="col-12 mt-1" />

          <b-form-group
            label="Possui renda com outra periodicidade (anual, semestral, outros)? "
            class="col-12 col-md-6 col-lg-3"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_renda.pessoa_renda_possui_renda_anual
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>
          <i
            v-b-tooltip.hover
            title="Bonus, dividendos, participacao nos lucros, etc"
            class="bx bx-help-circle"
            style="font-size: 1.2rem"
          />

          <b-col
            cols="12"
            class="mb-3"
            v-show="
              customer.pessoa.pessoa_renda.pessoa_renda_possui_renda_anual
            "
          >
            <b-form-row
              class="col-12 align-items-center"
              v-for="(annualIncome, index) in customer.pessoa.pessoa_renda
                .pessoa_renda_anuais"
              :key="`annual-${index}`"
            >
              <b-form-group
                label="Qual?"
                class="col-12 col-md-4 col-lg-3"
                :label-for="`income-renda-anual-${index}-nome-input`"
              >
                <b-form-input
                  :id="`income-renda-anual-${index}-nome-input`"
                  placeholder="ex: Aluguel/Dividendos"
                  v-model="annualIncome.nome"
                />
              </b-form-group>

              <b-form-group
                label="Quanto?"
                class="col-12 col-md-3 col-lg-3"
                :label-for="`income-renda-anual-${index}-valor-input`"
              >
                <money
                  class="form-control"
                  :id="`income-renda-anual-${index}-valor-input`"
                  v-bind="money"
                  v-model="annualIncome.valor"
                />
              </b-form-group>

              <b-form-group
                label="Periodicidade"
                class="col-12 col-md-3 col-lg-3"
                :label-for="`income-renda-anual-${index}-periodicidade-input`"
              >
                <b-form-input
                  :id="`income-renda-anual-${index}-periodicidade-input`"
                  placeholder="ex: 12"
                  v-model="annualIncome.periodicidade"
                />
              </b-form-group>

              <b-col md="2" lg="3" class="text-right">
                <b-button
                  pill
                  variant="danger"
                  @click="() => handleDeleteAnnualIncome(index)"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </b-col>
            </b-form-row>

            <b-button-group>
              <b-button @click="() => addAnnualIncome()" variant="primary">
                ADICIONAR RENDA ANUAL / SEMESTRAL
              </b-button>

              <b-button @click="() => addAnnualIncome()" variant="dark-primary">
                <i class="bx bx-plus" style="font-size: 0.95rem"></i>
              </b-button>
            </b-button-group>
          </b-col>

          <hr class="col-12 mt-1" />

          <b-form-group
            label="Qual valor total anual dos rendimentos tributáveis"
            class="col-12"
            label-for="income-valor-rendimentos-tributaveis-input"
          >
            <money
              class="form-control"
              id="income-valor-rendimentos-tributaveis-input"
              v-bind="money"
              v-model="
                customer.pessoa.pessoa_renda.valor_rendimentos_tributaveis
              "
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-piggy-bank"></i>
        <span class="ml-2">Confirme a média da sua poupança mensal: </span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label=""
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-media-poupanca-total-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.media_poupanca_total"
              id="expense-media-poupanca-total-input"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="income-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_renda.pessoa_renda_observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';
import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  mixins: [validationMixin],

  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    };
  },
  methods: {
    addMonthlyIncome() {
      this.customer.pessoa.pessoa_renda.pessoa_renda_mensais.push(
        cloneObject(defaultData.pessoa_renda_mensal)
      );
    },
    handleDeleteMonthlyIncome(index) {
      this.customer.pessoa.pessoa_renda.pessoa_renda_mensais.splice(index, 1);
    },
    addAnnualIncome() {
      this.customer.pessoa.pessoa_renda.pessoa_renda_anuais.push(
        cloneObject(defaultData.pessoa_renda_anual)
      );
    },
    handleDeleteAnnualIncome(index) {
      this.customer.pessoa.pessoa_renda.pessoa_renda_anuais.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
