<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-wallet"></i>
        <span class="ml-2">Conte-nos um pouco sobre suas despesas mensais</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual o valor da sua despesa fixa familiar mensal?"
            class="col-12 col-md-6 col-lg-4"
            label-for="expense-despesa-fixa-input"
            style="position: relative"
          >
            <i
              v-b-tooltip.hover
              title="Não inclua as suas despesas com a aquisição de patrimônio (consórcio, financiamento, outros)"
              class="bx bx-help-circle"
              style="
                font-size: 1.2rem;
                position: absolute;
                top: 0rem;
                right: -1rem;
              "
            ></i>
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.despesa_fixa_mensal"
              id="expense-despesa-fixa-input"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      v-for="(expense, index) in customer.pessoa.patrimonio_investimento
        .patrimonio_itens"
      :key="index"
      class="diagnosis-info-section"
    >
      <b-card-title
        class="text-primary d-flex align-items-center justify-content-between"
      >
        <span>
          <i class="fas fa-balance-scale-left"></i>
          <span class="ml-2">
            Despesa mensal com Construção de Patrimônio - {{ index + 1 }}
          </span>
        </span>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeleteExpense(index)"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            :label="`Nome Despesa mensal${
              possuiPatrimonioNaoQuitado ? ' com a contrução do patrimônio' : ''
            }:`"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-name-input`"
          >
            <b-form-input
              v-model="expense.nome"
              placeholder="Ex: Apartamento, Carro"
              :id="`expense-mensal-${index}-name-input`"
            />
          </b-form-group>

          <b-form-group
            label="Forma de construção: "
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-forma-contrucao-input`"
            v-show="!expense.quitado"
          >
            <b-form-select
              :id="`expense-mensal-${index}-forma-contrucao-input`"
              v-model="expense.despesa_forma_construcao"
              :options="formaConstrucaoOptions"
            />
          </b-form-group>

          <b-form-group label="Está quitado?" class="col-12 col-md-6 col-lg-4">
            <b-form-radio-group
              v-model="expense.quitado"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual a instituição financeira? "
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-qual-operadora-input`"
            v-show="!expense.quitado"
          >
            <b-form-input
              v-model="expense.despesa_operadora"
              placeholder="Instituição financeira"
              :id="`expense-mensal-${index}-qual-operadora-input`"
            />
          </b-form-group>

          <b-form-group
            v-show="!expense.quitado"
            label="Valor mensal pago:"
            class="col-12 col-md-6 col-lg-2"
            :label-for="`expense-mensal-${index}-valor-mensal-input`"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="expense.valor_mensal"
              :id="`expense-mensal-${index}-valor-mensal-input`"
            />
          </b-form-group>

          <b-form-group
            v-show="!expense.quitado"
            label="Saldo:"
            class="col-12 col-md-6 col-lg-2"
            :label-for="`expense-mensal-${index}-saldo-input`"
          >
            <money
              v-model="expense.despesa_saldo_pagar_financeamento"
              class="form-control"
              v-bind="money"
              :id="`expense-mensal-${index}-saldo-input`"
            />
          </b-form-group>

          <b-form-group
            v-show="!expense.quitado"
            label="Quantas parcelas faltam pagar?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-parcelas-faltantes-input`"
          >
            <b-form-input
              v-model="expense.despesa_parcelas_restantes"
              type="number"
              :id="`expense-mensal-${index}-parcelas-faltantes-input`"
            />
          </b-form-group>

          <b-form-group
            v-show="!expense.quitado"
            :label="
              expense.despesa_forma_construcao == 'Financiamento'
                ? `Taxa de juros ao ano`
                : expense.despesa_forma_construcao == 'Consórcio'
                ? `Taxa da operação de consórcio`
                : 'Taxa'
            "
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-juros-financiamento-input`"
          >
            <money
              :id="`expense-mensal-${index}-juros-financiamento-input`"
              class="form-control"
              v-bind="percentage"
              v-model="expense.despesa_taxa_juros_financeamento"
            />
          </b-form-group>

          <b-form-group
            v-show="!expense.quitado"
            label="Indice de correção:"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`expense-mensal-${index}-indice_de_correcao-input`"
          >
            <b-form-select
              :id="`expense-mensal-${index}-indice_de_correcao-input`"
              v-model="expense.despesa_indice_correcao"
              :options="indiceCorrecaoOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-button-group class="mb-3">
      <b-button @click="() => addExpense()" variant="primary">
        ADICIONAR DESPESA
      </b-button>
      <b-button @click="() => addExpense()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-wallet"></i>
        <span class="ml-2">Conte-nos um pouco sobre suas despesas anuais</span>
        <i
          v-b-tooltip.hover
          title="Consulte a sua declaração de Imposto de Renda. Replica resumida de: http://www.receita.fazenda.gov.br/aplicacoes/atrjo/simulador/simulador.asp?tipoSimulador=M"
          class="bx bx-help-circle"
          style="font-size: 1.2rem"
        ></i>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Tipo de declaração do imposto de renda:"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-tipo-imposto-renda-input"
          >
            <b-form-select
              id="expense-tipo-imposto-renda-input"
              v-model="customer.pessoa.pessoa_despesa.tipo_declaracao_ir"
              :options="tipoDeclaracaoIrOptions"
              :state="
                errors['pessoa_despesa.tipo_declaracao_ir'] ? false : null
              "
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_despesa.tipo_declaracao_ir']"
            >
              {{ errors['pessoa_despesa.tipo_declaracao_ir'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Contribuição INSS (ou previdencia publica)"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-contribuicao-inss-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.contribuicao_inss"
              id="expense-contribuicao-inss-input"
            />
          </b-form-group>

          <b-form-group
            label="Quantos dependentes?"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-quantos-dependentes-input"
          >
            <b-form-input
              class="form-control"
              type="text"
              v-model="customer.pessoa.pessoa_despesa.quantos_dependentes"
              id="expense-quantos-dependentes-input"
              v-mask="'###'"
            />
          </b-form-group>

          <b-form-group
            :label="`Dependentes (limite ${
              (customer.pessoa.pessoa_despesa.quantos_dependentes || 1) *
              2275.08
            })`"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-dependentes-input"
          >
            <money
              :class="`form-control ${
                errors['pessoa_despesa.dependentes'] ? 'is-invalid' : ''
              }`"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.dependentes"
              id="expense-dependentes-input"
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_despesa.dependentes']"
            >
              {{ errors['pessoa_despesa.dependentes'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Quantos alimentando?"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-quantos-dependentes-alimentando-input"
          >
            <b-form-input
              class="form-control"
              type="text"
              v-model="customer.pessoa.pessoa_despesa.quantos_alimentando"
              id="expense-quantos-dependentes-alimentando-input"
              v-mask="'###'"
            />
          </b-form-group>

          <b-form-group
            label="Despesas com educação (limite 3.561,60)"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-despesas-educacao-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.despesas_educacao"
              id="expense-despesas-educacao-input"
            />
          </b-form-group>

          <b-form-group
            label="Despesas médicas(sem limite)"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-despesas-medicas-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.despesas_medicas"
              id="expense-despesas-medicas-input"
            />
          </b-form-group>

          <b-form-group
            label="Pensão alimentícia"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-pensao-alimenticia-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.pensao_alimenticia"
              id="expense-pensao-alimenticia-input"
            />
          </b-form-group>

          <b-form-group
            label="PGBL / FAPI / Funpresp"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-pensao-alimenticia-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.pgbl_fapi_funpresp"
              id="expense-pensao-alimenticia-input"
            />
          </b-form-group>

          <b-form-group
            label="Livro caixa (profissionais liberais)"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-livro-caixa-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.livro_caixa"
              id="expense-livro-caixa-input"
            />
          </b-form-group>

          <b-form-group
            label="Outras despesas"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-outras-despesas-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.outras_despesas"
              id="expense-outras-despesas-input"
            />
          </b-form-group>

          <b-form-group
            label="Imposto de renda retido / pago + deduções de inventivo (doações legais)"
            class="col-12 col-md-6 col-lg-3"
            label-for="expense-doacoes-legais-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.ir_retido_deducoes"
              id="expense-doacoes-legais-input"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-percentage"></i>
        <span class="ml-2">Do valor total de despesas. quanto cabe a:</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="A você?"
            class="col-12 col-md-6 col-lg-4"
            label-for="expense-you-input"
          >
            <money
              id="expense-you-input"
              class="form-control"
              placeholder="Ex: 100%"
              v-bind="percentage"
              v-model="customer.pessoa.pessoa_despesa.contribuicao_cliente"
            />
          </b-form-group>

          <b-form-group
            v-if="['C', 'UE'].includes(customer.pessoa.pessoa_estado_civil)"
            label="Ao seu conjuge?"
            class="col-12 col-md-6 col-lg-4"
            label-for="expense-spouse-input"
          >
            <money
              id="expense-spouse-input"
              class="form-control"
              placeholder="Ex: 100%"
              v-bind="percentage"
              v-model="customer.pessoa.pessoa_despesa.contribuicao_conjuge"
            />
          </b-form-group>

          <b-form-group
            label="Mais alguém contribui?"
            class="col-12 col-md-6 col-lg-4"
            label-for="expense-other-input"
          >
            <money
              id="expense-other-input"
              class="form-control"
              placeholder="Ex: 100%"
              v-bind="percentage"
              v-model="customer.pessoa.pessoa_despesa.outro_contribuinte"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <b-form-row>
        <b-form-group
          label="Tem interesse em fazer um estudo para poupar mais sem mudar seu padrao de vida?"
          class="col-12 col-md-6 col-lg-4"
        >
          <b-form-radio-group
            v-model="
              customer.pessoa.pessoa_despesa.interesse_estudo_poupar_mais
            "
            :options="yesOrNotOptions"
          />
        </b-form-group>
      </b-form-row>

      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="expense-comment-input">
          <b-form-textarea
            id="expense-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_despesa.observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';

import {
  tipoDeclaracaoIrOptions,
  formaConstrucaoOptions,
  indiceCorrecaoOptions
} from '@/constants/options';

import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      percentage: {
        decimal: ',',
        thousands: '.',
        suffix: ' %',
        precision: 2,
        masked: false
      },
      tipoDeclaracaoIrOptions,
      formaConstrucaoOptions,
      indiceCorrecaoOptions
    };
  },
  methods: {
    addExpense() {
      this.customer.pessoa.patrimonio_investimento.patrimonio_itens.push(
        cloneObject(defaultData.patrimonio_item)
      );
    },
    handleDeleteExpense(index) {
      this.customer.pessoa.patrimonio_investimento.patrimonio_itens.splice(
        index,
        1
      );
    }
  },
  computed: {
    possuiPatrimonioNaoQuitado() {
      return this.customer.pessoa.patrimonio_investimento.patrimonio_itens.some(
        (patrimonio) => patrimonio.quitado
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
