<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-user-circle"></i>
        <span class="ml-2">Perfil</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual o seu nome completo?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-name-input"
          >
            <b-form-input
              placeholder="Nome completo"
              v-model="customer.pessoa.pessoa_nome"
              id="customer-profile-name-input"
              name="customer-profile-name-input"
              :state="errors.pessoa_nome ? false : null"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_nome">
              {{ errors.pessoa_nome.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual a sua data de nascimento?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-birth-date-input"
            :state="errors.pessoa_data_nascimento ? false : null"
          >
            <b-form-input
              v-model="customer.pessoa.pessoa_data_nascimento"
              type="date"
              id="customer-profile-birth-date-input"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_data_nascimento">
              {{ errors.pessoa_data_nascimento.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Sexo"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-gender-input"
            :state="errors.pessoa_sexo ? false : null"
          >
            <b-form-select
              id="customer-profile-gender-input"
              v-model="customer.pessoa.pessoa_sexo"
              :options="sexOptions"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_sexo">
              {{ errors.pessoa_sexo.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Grau de instrução"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-education-level-input"
          >
            <b-form-select
              id="customer-profile-education-level-input"
              v-model="customer.pessoa.pessoa_grau_instrucao"
              :options="educationLevelOptions"
            />
          </b-form-group>

          <b-form-group
            label="Situação de trabalho?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-work-situation-input"
          >
            <b-form-select
              id="customer-profile-work-situation-input"
              v-model="customer.pessoa.pessoa_situacao_trabalho"
              :options="workSituationOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual sua profissão?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-occupation-input"
          >
            <model-select
              id="customer-profile-occupation-input"
              v-model="customer.pessoa.pessoa_profissao"
              :options="occupationOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual a profissão?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-outra-profissao-input"
            :state="errors.pessoa_outra_profissao ? false : null"
            v-show="customer.pessoa.pessoa_profissao == 'Outra'"
          >
            <b-form-input
              id="customer-profile-outra-profissao-input"
              type="text"
              v-model="customer.pessoa.pessoa_outra_profissao"
              placeholder="Profissão"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_outra_profissao">
              {{ errors.pessoa_outra_profissao.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual seu CPF?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-cpf-input"
            :state="errors.pessoa_cpf ? false : null"
          >
            <b-form-input
              id="customer-profile-cpf-input"
              v-mask="'###.###.###-##'"
              type="text"
              v-model="customer.pessoa.pessoa_cpf"
              placeholder="CPF"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_cpf">
              {{ errors.pessoa_cpf.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual o tipo do seu documento de identificação?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-select
              v-model="customer.pessoa.tipo_documento_identificacao"
              :options="tipoDocumentoIdentificacaoOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual seu documento de identificação?"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-documento-identificacao-input"
          >
            <b-form-input
              id="customer-profile-documento-identificacao-input"
              v-model="customer.pessoa.documento_identificacao"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="E-mail"
            class="col-12 col-md-6 col-lg-4"
            label-for="customer-profile-email-input"
            :state="errors.pessoa_email ? false : null"
          >
            <b-form-input
              id="customer-profile-email-input"
              type="text"
              v-model="customer.pessoa.pessoa_email"
              placeholder="E-mail"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_email">
              {{ errors.pessoa_email.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Telefone"
            class="col-12 col-md-6 col-lg-4"
            :state="errors.pessoa_telefone ? false : null"
          >
            <VuePhoneNumberInput
              :translations="numberInputTranslations"
              v-model="customer.pessoa.pessoa_telefone"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_telefone">
              {{ errors.pessoa_telefone.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group label="Celular" class="col-12 col-md-6 col-lg-4">
            <VuePhoneNumberInput
              :translations="numberInputTranslations"
              v-model="customer.pessoa.pessoa_celular"
            />
          </b-form-group>

          <b-form-group
            label="Preferência de contato"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-select
              v-model="customer.pessoa.pessoa_preferencia_contato"
              :options="contactOptions"
            />
          </b-form-group>

          <b-form-group label="Estado civil?" class="col-12 col-md-6 col-lg-4">
            <b-form-select
              v-model="customer.pessoa.pessoa_estado_civil"
              :options="estadoCivilOptions"
              :state="errors.pessoa_estado_civil ? false : null"
              id="customer-profile-marital-status-feedback"
            />

            <b-form-invalid-feedback v-if="errors.pessoa_estado_civil">
              {{ errors.pessoa_estado_civil.join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            v-if="['C', 'UE'].includes(customer.pessoa.pessoa_estado_civil)"
            label="Qual regime da comunhão?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-select
              v-model="customer.pessoa.pessoa_regime_comunhao"
              :options="comunhaoOptions"
            />
          </b-form-group>

          <b-col cols="12">
            <b-form-row class="align-items-end">
              <b-form-group
                label="Está estudando?"
                :class="`col-12 ${
                  customer.pessoa.estudo.esta_estudando
                    ? 'col-md-6 col-lg-4'
                    : ''
                }`"
              >
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="customer.pessoa.estudo.esta_estudando"
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Faltam quantos anos para concluir os estudos?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-tempo-restante-input"
                v-show="customer.pessoa.estudo.esta_estudando"
              >
                <b-form-input
                  id="family-profile-tempo-restante-input"
                  type="text"
                  v-model="customer.pessoa.estudo.tempo_restante"
                  v-mask="'##'"
                />
              </b-form-group>

              <b-form-group
                label="Qual valor médio da mensalidade?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-valor-mensalidade-input"
                v-show="customer.pessoa.estudo.esta_estudando"
              >
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="customer.pessoa.estudo.valor_mensalidade"
                  id="family-profile-valor-mensalidade-input"
                />
              </b-form-group>

              <b-form-group
                label="Pretende estudar fora do país?"
                :class="`col-12 ${
                  customer.pessoa.estudo.intercambio
                    ? 'col-md-6 col-lg-4 col-xl-3'
                    : ''
                }`"
              >
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="customer.pessoa.estudo.intercambio"
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Por quantos anos?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-intercambio-tempo-input"
                v-show="customer.pessoa.estudo.intercambio"
              >
                <b-form-input
                  type="text"
                  id="family-profile-intercambio-tempo-input"
                  v-model="customer.pessoa.estudo.intercambio_tempo"
                  v-mask="'##'"
                />
              </b-form-group>

              <b-form-group
                label="Qual valor médio da mensalidade + custos no exterior?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-intercambio-valor-input"
                v-show="customer.pessoa.estudo.intercambio"
              >
                <money
                  class="form-control"
                  v-bind="money"
                  id="family-profile-intercambio-valor-input"
                  v-model="customer.pessoa.estudo.intercambio_valor"
                />
              </b-form-group>

              <b-form-group
                label="Em que ano isso vai acontecer?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-ano-intercambio-tempo-input"
                v-show="customer.pessoa.estudo.intercambio"
              >
                <b-form-input
                  type="text"
                  id="family-profile-ano-intercambio-tempo-input"
                  v-model="customer.pessoa.estudo.ano_intercambio"
                  v-mask="'####'"
                />
              </b-form-group>

              <b-form-group
                style="position: relative"
                label="Possui seguro de vida educacional?"
                class="col-12 col-md-6 col-lg-4"
              >
                <i
                  v-b-tooltip.hover
                  title="Seguro inserido no valor da mensalidade"
                  class="bx bx-help-circle"
                  style="
                    font-size: 1.2rem;
                    position: absolute;
                    top: -1.8rem;
                    left: 250px;
                  "
                ></i>
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="customer.pessoa.estudo.possui_seguro_educacional"
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Por quantos anos?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-intercambio-seguro-tempo-input"
                v-show="customer.pessoa.estudo.possui_seguro_educacional"
              >
                <b-form-input
                  type="text"
                  id="family-profile-intercambio-seguro-tempo-input"
                  v-model="customer.pessoa.estudo.tempo_seguro_educacional"
                  v-mask="'##'"
                />
              </b-form-group>
            </b-form-row>
          </b-col>

          <b-form-group label="Este cliente possui dependentes?" class="col-12">
            <div class="d-flex mt-3">
              <b-form-radio-group
                v-model="
                  customer.pessoa.perfil_familia
                    .perfil_familia_possui_dependentes
                "
                :options="yesOrNotOptions"
              />
            </div>
          </b-form-group>

          <b-form-group
            label="Esse possui algum dependente financeiro além dos seus filhos?"
            :class="`col-12 ${
              customer.pessoa.pessoa_plano.planob_possui_dependente_alem_filhos
                ? 'col-md-6'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_plano
                  .planob_possui_dependente_alem_filhos
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      v-if="['C', 'UE'].includes(customer.pessoa.pessoa_estado_civil)"
      class="diagnosis-info-section"
    >
      <b-card-title class="text-primary">
        <i class="fas fa-user-circle"></i>
        <span class="ml-2">Cônjuge</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <div class="diagnosis-info-content">
            <b-form-row class="align-items-end">
              <b-form-group
                label="Qual o seu nome completo?"
                class="col-12 col-md-6 col-lg-4"
                label-for="customer-profile-spouse-name-input"
              >
                <b-form-input
                  placeholder="Nome completo"
                  id="customer-profile-spouse-name-input"
                  v-model="customer.pessoa.pessoa_conjuge.nome_conjuge"
                />
              </b-form-group>

              <b-form-group
                label="Qual a sua data de nascimento?"
                class="col-12 col-md-6 col-lg-4"
                label-for="customer-profile-spouse-birth-date-input"
              >
                <b-form-input
                  type="date"
                  id="customer-profile-spouse-birth-date-input"
                  v-model="customer.pessoa.pessoa_conjuge.data_nasc_conjuge"
                  :state="
                    errors['pessoa_conjuge.data_nasc_conjuge'] ? false : null
                  "
                />

                <b-form-invalid-feedback
                  v-if="errors['pessoa_conjuge.data_nasc_conjuge']"
                >
                  {{ errors['pessoa_conjuge.data_nasc_conjuge'].join(', ') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <!-- <b-form-group
                label="Qual seu CPF?"
                class="col-12 col-md-6 col-lg-3"
                label-for="customer-profile-spouse-cpf-input"
              >
                <b-form-input
                  id="customer-profile-spouse-cpf-input"
                  v-mask="'###.###.###-##'"
                  type="text"
                  placeholder="CPF"
                  v-model="customer.pessoa.pessoa_conjuge.cpf_conjuge"
                />
              </b-form-group>

              <b-form-group
                label="Preferência de contato"
                class="col-12 col-md-6 col-lg-3"
              >
                <b-form-select
                  v-model="customer.pessoa.pessoa_conjuge.pref_contato_conjuge"
                  :options="contactOptions"
                />
              </b-form-group>

              <b-form-group
                label="E-mail"
                class="col-12 col-md-6 col-lg-3"
                label-for="customer-profile-spouse-cpf-input"
              >
                <b-form-input
                  id="customer-profile-spouse-cpf-input"
                  type="text"
                  placeholder="E-mail"
                  v-model="customer.pessoa.pessoa_conjuge.email_conjuge"
                  :state="errors['pessoa_conjuge.email_conjuge'] ? false : null"
                />

                <b-form-invalid-feedback
                  v-if="errors['pessoa_conjuge.email_conjuge']"
                >
                  {{ errors['pessoa_conjuge.email_conjuge'].join(', ') }}
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group label="Telefone" class="col-12 col-md-6 col-lg-3">
                <VuePhoneNumberInput
                  :translations="numberInputTranslations"
                  v-model="customer.pessoa.pessoa_conjuge.telefone_conjuge"
                />
              </b-form-group>

              <b-form-group label="Celular" class="col-12 col-md-6 col-lg-3">
                <VuePhoneNumberInput
                  :translations="numberInputTranslations"
                  v-model="customer.pessoa.pessoa_conjuge.celular_conjuge"
                />
              </b-form-group> -->

              <b-form-group
                label="Como gostaria de avaliar suas necessiades?"
                class="col-12 col-md-6 col-lg-4"
              >
                <b-form-select
                  v-model="customer.pessoa.pessoa_avaliacao_necessidade"
                  :options="avaliacaoNecessidadeOptions"
                />
              </b-form-group>

              <b-form-group
                label="Grau de instrução"
                class="col-12 col-md-6 col-lg-4"
                label-for="customer-profile-education-level-input"
              >
                <b-form-select
                  id="customer-profile-education-level-input"
                  v-model="
                    customer.pessoa.pessoa_conjuge.grau_instrucao_conjuge
                  "
                  :options="educationLevelOptions"
                />
              </b-form-group>

              <b-form-group
                label="Situação de trabalho?"
                class="col-12 col-md-6 col-lg-4"
                label-for="customer-profile-work-situation-input"
              >
                <b-form-select
                  id="customer-profile-work-situation-input"
                  v-model="
                    customer.pessoa.pessoa_conjuge.situacao_trabalho_conjuge
                  "
                  :options="workSituationOptions"
                />
              </b-form-group>

              <b-form-group
                label="Qual sua profissão?"
                class="col-12 col-md-6 col-lg-4"
                label-for="customer-profile-occupation-input"
              >
                <model-select
                  id="customer-profile-occupation-input"
                  v-model="customer.pessoa.pessoa_conjuge.profissao_conjuge"
                  :options="occupationOptions"
                />
              </b-form-group>

              <b-form-group
                label="Está estudando?"
                :class="`col-12 ${
                  customer.pessoa.pessoa_conjuge.estudo.esta_estudando
                    ? 'col-md-6 col-lg-4'
                    : ''
                }`"
              >
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_conjuge.estudo.esta_estudando
                    "
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Faltam quantos anos para concluir os estudos?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-conjuge-tempo-restante-input"
                v-show="customer.pessoa.pessoa_conjuge.estudo.esta_estudando"
              >
                <b-form-input
                  id="family-profile-conjuge-tempo-restante-input"
                  type="text"
                  v-model="customer.pessoa.pessoa_conjuge.estudo.tempo_restante"
                  v-mask="'##'"
                />
              </b-form-group>

              <b-form-group
                label="Qual valor médio da mensalidade?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-conjuge-valor-mensalidade-input"
                v-show="customer.pessoa.pessoa_conjuge.estudo.esta_estudando"
              >
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="
                    customer.pessoa.pessoa_conjuge.estudo.valor_mensalidade
                  "
                  id="family-profile-conjuge-valor-mensalidade-input"
                />
              </b-form-group>

              <b-form-group
                label="O titular é responsável pelo pagamento dos estudos?"
                class="col-12"
              >
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_conjuge.estudo
                        .titular_responsavel_pagamento
                    "
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Pretende estudar fora do país?"
                :class="`col-12 ${
                  customer.pessoa.pessoa_conjuge.estudo.intercambio
                    ? 'col-md-6 col-lg-4 col-xl-3'
                    : ''
                }`"
              >
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="customer.pessoa.pessoa_conjuge.estudo.intercambio"
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Se sim, Por quantos anos?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-conjuge-intercambio-tempo-input"
                v-show="customer.pessoa.pessoa_conjuge.estudo.intercambio"
              >
                <b-form-input
                  type="text"
                  v-model="
                    customer.pessoa.pessoa_conjuge.estudo.intercambio_tempo
                  "
                  id="family-profile-conjuge-intercambio-tempo-input"
                  v-mask="'##'"
                />
              </b-form-group>

              <b-form-group
                label="Qual valor médio da mensalidade + custos no exterior?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-conjuge-intercambio-valor-input"
                v-show="customer.pessoa.pessoa_conjuge.estudo.intercambio"
              >
                <money
                  class="form-control"
                  v-bind="money"
                  v-model="
                    customer.pessoa.pessoa_conjuge.estudo.intercambio_valor
                  "
                  id="family-profile-conjuge-intercambio-valor-input"
                />
              </b-form-group>

              <b-form-group
                label="Em que ano isso vai acontecer?"
                class="col-12 col-md-6 col-lg-4 col-xl-3"
                label-for="family-profile-conjuge-ano-intercambio-tempo-input"
                v-show="customer.pessoa.pessoa_conjuge.estudo.intercambio"
              >
                <b-form-input
                  type="text"
                  v-model="
                    customer.pessoa.pessoa_conjuge.estudo.ano_intercambio
                  "
                  id="family-profile-conjuge-ano-intercambio-tempo-input"
                  v-mask="'####'"
                />
              </b-form-group>

              <b-form-group
                :label="`Possui seguro de vida educacional?`"
                style="position: relative"
                class="col-12 col-md-6 col-lg-4"
              >
                <i
                  v-b-tooltip.hover
                  title="Seguro inserido no valor da mensalidade"
                  class="bx bx-help-circle"
                  style="
                    font-size: 1.2rem;
                    position: absolute;
                    top: -1.8rem;
                    left: 250px;
                  "
                ></i>
                <div class="d-flex mt-3">
                  <b-form-radio-group
                    v-model="
                      customer.pessoa.pessoa_conjuge.estudo
                        .possui_seguro_educacional
                    "
                    :options="yesOrNotOptions"
                  />
                </div>
              </b-form-group>

              <b-form-group
                label="Se sim, Por quantos anos?"
                class="col-12 col-md-6 col-lg-4"
                label-for="family-profile-conjuge-seguro-tempo-input"
                v-show="
                  customer.pessoa.pessoa_conjuge.estudo
                    .possui_seguro_educacional
                "
              >
                <b-form-input
                  type="text"
                  v-model="
                    customer.pessoa.pessoa_conjuge.estudo
                      .tempo_seguro_educacional
                  "
                  id="family-profile-conjuge-seguro-tempo-input"
                  v-mask="'##'"
                />
              </b-form-group>
            </b-form-row>
          </div>
        </b-form-row>
      </div>
    </b-card>

    <div
      v-show="customer.pessoa.perfil_familia.perfil_familia_possui_dependentes"
    >
      <b-card
        v-for="(dependent, index) in customer.pessoa.dependentes"
        :key="dependent.id"
        class="diagnosis-info-section"
      >
        <b-card-title
          class="text-primary d-flex align-items-center justify-content-between"
        >
          <span>
            <i class="fas fa-user-circle"></i>
            <span class="ml-2">Dependente {{ index + 1 }}</span>
          </span>

          <b-button
            pill
            variant="danger"
            @click="() => handleDeleteDependent(index)"
          >
            <i class="fas fa-trash-alt" />
          </b-button>
        </b-card-title>

        <div class="diagnosis-info-content">
          <b-form-row class="align-items-end">
            <b-form-group
              label="Nome"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`family-profile-dependent-${index}-name-input`"
            >
              <b-form-input
                v-model="dependent.nome_dependente"
                :id="`family-profile-dependent-${index}-name-input`"
              />
            </b-form-group>

            <b-form-group
              label="Data de nascimento"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`family-profile-dependent-${index}-birth-date-input`"
            >
              <b-form-input
                v-model="dependent.data_nasc_dependente"
                type="date"
                :id="`family-profile-dependent-${index}-birth-date-input`"
              />
            </b-form-group>

            <b-form-group
              label="Grau de parentesco"
              class="col-12 col-md-6 col-lg-4"
            >
              <b-form-select
                v-model="dependent.grau_parentesco_dependente"
                :options="parentescoOptions"
              />
            </b-form-group>

            <b-form-group
              v-if="dependent.grau_parentesco_dependente === 'Outro'"
              label="Qual outro grau de parentesco?"
              class="col-12 col-md-6 col-lg-4"
            >
              <b-form-input value="" />
            </b-form-group>

            <b-form-group
              label="Está estudando?"
              :class="`col-12 ${
                dependent.estudo.esta_estudando ? 'col-md-6 col-lg-4' : ''
              }`"
            >
              <div class="d-flex mt-3">
                <b-form-radio-group
                  v-model="dependent.estudo.esta_estudando"
                  :options="yesOrNotOptions"
                  @change="(val) => handleDependentStudy(val, dependent)"
                />
              </div>
            </b-form-group>

            <b-form-group
              label="Faltam quantos anos para concluir os estudos?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`family-profile-dependent-${index}-tempo-restante-input`"
              v-show="dependent.estudo.esta_estudando"
            >
              <b-form-input
                :id="`family-profile-dependent-${index}-tempo-restante-input`"
                type="text"
                v-model="dependent.estudo.tempo_restante"
                v-mask="'##'"
              />
            </b-form-group>

            <b-form-group
              label="Qual o valor que voce estima como uma média mensal até a conclusao dos estudos?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`family-profile-dependent-${index}-valor-mensalidade-input`"
              v-show="dependent.estudo.esta_estudando"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="dependent.estudo.valor_mensalidade"
                :id="`family-profile-dependent-${index}-valor-mensalidade-input`"
              />
            </b-form-group>

            <b-form-group
              label="O titular é responsável pelo pagamento dos estudos?"
              class="col-12"
            >
              <div class="d-flex mt-3">
                <b-form-radio-group
                  v-model="dependent.estudo.titular_responsavel_pagamento"
                  :options="yesOrNotOptions"
                />
              </div>
            </b-form-group>

            <b-form-group
              label="Pretende estudar fora do país?"
              :class="`col-12 ${
                dependent.estudo.intercambio ? 'col-md-6 col-lg-4 col-xl-3' : ''
              }`"
            >
              <div class="d-flex mt-3">
                <b-form-radio-group
                  v-model="dependent.estudo.intercambio"
                  :options="yesOrNotOptions"
                />
              </div>
            </b-form-group>

            <b-form-group
              label="Se sim, Por quantos anos?"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              :label-for="`family-profile-dependent-${index}-intercambio-tempo-input`"
              v-show="dependent.estudo.intercambio"
            >
              <b-form-input
                type="text"
                v-model="dependent.estudo.intercambio_tempo"
                :id="`family-profile-dependent-${index}-intercambio-tempo-input`"
                v-mask="'##'"
              />
            </b-form-group>

            <b-form-group
              label="Qual valor médio da mensalidade + custos no exterior?"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              :label-for="`family-profile-dependent-${index}-intercambio-valor-input`"
              v-show="dependent.estudo.intercambio"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="dependent.estudo.intercambio_valor"
                :id="`family-profile-dependent-${index}-intercambio-valor-input`"
              />
            </b-form-group>

            <b-form-group
              label="Em que ano isso vai acontecer?"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              :label-for="`family-profile-dependent-${index}-ano-intercambio-tempo-input`"
              v-show="dependent.estudo.intercambio"
            >
              <b-form-input
                type="text"
                v-model="dependent.estudo.ano_intercambio"
                :id="`family-profile-dependent-${index}-ano-intercambio-tempo-input`"
                v-mask="'####'"
              />
            </b-form-group>

            <b-form-group
              label="Possui seguro de vida educacional?"
              class="col-12 col-md-6 col-lg-4"
              style="position: relative"
            >
              <i
                v-b-tooltip.hover
                title="Seguro inserido no valor da mensalidade"
                class="bx bx-help-circle"
                style="
                  font-size: 1.2rem;
                  position: absolute;
                  top: -1.8rem;
                  left: 250px;
                "
              ></i>
              <div class="d-flex mt-3">
                <b-form-radio-group
                  v-model="dependent.estudo.possui_seguro_educacional"
                  :options="yesOrNotOptions"
                />
              </div>
            </b-form-group>

            <b-form-group
              label="Se sim, Por quantos anos?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`family-profile-dependent-${index}-seguro-tempo-input`"
              v-show="dependent.estudo.tempo_seguro_educacional"
            >
              <b-form-input
                type="text"
                v-model="dependent.estudo.tempo_seguro_educacional"
                :id="`family-profile-dependent-${index}-seguro-tempo-input`"
                v-mask="'##'"
              />
            </b-form-group>
          </b-form-row>
        </div>
      </b-card>
    </div>

    <b-button-group
      @click="() => addDependent()"
      class="mb-3"
      v-show="customer.pessoa.perfil_familia.perfil_familia_possui_dependentes"
    >
      <b-button variant="primary">ADICIONAR DEPENDENDE</b-button>

      <b-button variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <div class="diagnosis-info-content">
      <div
        v-if="customer.pessoa.pessoa_plano.planob_possui_dependente_alem_filhos"
      >
        <b-form-row
          class="align-items-end"
          v-for="(dependentPlan, index) in customer.pessoa.dependentes_plano"
          :key="index"
        >
          <b-form-group
            label="Grau de parentesco"
            class="col-12 col-md-6 col-lg-4 col-xl-3"
          >
            <b-form-select
              v-model="dependentPlan.grau_parentesco"
              :options="parentescoOutroDependenteOptions"
            />
          </b-form-group>

          <b-form-group
            v-if="dependentPlan.grau_parentesco === 'Outro'"
            label="Qual outro grau de parentesco?"
            class="col-12 col-md-6 col-lg-4 col-xl-3"
          >
            <b-form-input v-model="dependentPlan.grau_parentesco_customizado" />
          </b-form-group>

          <b-form-group
            label="Qual a renda mensal necessária?"
            class="col-12 col-md-6 col-lg-4 col-xl-3"
            label-for="plans-and-dreams-renda-mensal-dependente-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="dependentPlan.renda_mensal_necessaria"
              id="plans-and-dreams-renda-mensal-dependente-input"
            />
          </b-form-group>

          <b-form-group
            label="Por quanto tempo gostaria de manter esta proteção?"
            :class="`col-12 col-md-6 col-lg-4 col-xl-${
              dependentPlan.grau_parentesco === 'Outro' ? '3' : '4'
            }`"
            label-for="plans-and-dreams-por-quanto-tempo-manter-protecao-input"
          >
            <b-form-select
              id="plans-and-dreams-por-quanto-tempo-manter-protecao-input"
              v-model="dependentPlan.quanto_tempo_protecao"
              :options="tempoProtecaoOptions"
              :state="
                errors[`dependentes_plano.${index}.quanto_tempo_protecao`]
                  ? false
                  : null
              "
            />

            <b-form-invalid-feedback
              v-if="errors[`dependentes_plano.${index}.quanto_tempo_protecao`]"
            >
              {{
                errors[`dependentes_plano.${index}.quanto_tempo_protecao`].join(
                  ', '
                )
              }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-col
            :md="dependentPlan.grau_parentesco === 'Outro' ? '12' : '6'"
            :lg="dependentPlan.grau_parentesco === 'Outro' ? '8' : '12'"
            :xl="dependentPlan.grau_parentesco === 'Outro' ? '12' : '2'"
            class="text-right mb-3"
          >
            <b-button
              pill
              variant="danger"
              @click="() => handleDeleteDependentPlan(index)"
            >
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-col>
        </b-form-row>

        <b-button-group class="mb-3">
          <b-button @click="() => addDependentPlan()" variant="primary">
            INCLUIR DEPENDENTES ADICIONAIS
          </b-button>
          <b-button @click="() => addDependentPlan()" variant="dark-primary">
            <i class="bx bx-plus" style="font-size: 0.95rem"></i>
          </b-button>
        </b-button-group>
      </div>
    </div>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-address-card"></i>
        <span class="ml-2">Endereço</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="CEP"
            class="col-12 col-md-6 col-lg-4 col-xl-2"
            label-for="customer-profile-address-address-cep"
          >
            <b-form-input
              type="text"
              placeholder="CEP"
              v-mask="'#####-###'"
              @input="searchAddress(customer.pessoa.endereco.endereco_cep)"
              id="customer-profile-address-address-cep"
              v-model="customer.pessoa.endereco.endereco_cep"
              :state="errors['endereco.endereco_cep'] ? false : null"
            />

            <b-form-invalid-feedback v-if="errors['endereco.endereco_cep']">
              {{ errors['endereco.endereco_cep'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <template v-if="customer.pessoa.endereco.endereco_cidade">
            <!-- <b-form-group
              label="Bairro"
              class="col-12 col-md-6 col-lg-4"
              label-for="customer-profile-address-district-input"
            >
              <b-form-input
                type="text"
                placeholder="Bairro"
                id="customer-profile-address-district-input"
                v-model="customer.pessoa.endereco.endereco_bairro"
              />
            </b-form-group>

            <b-form-group
              label="Rua"
              class="col-12 col-md-6 col-lg-4"
              label-for="customer-profile-address-address-input"
            >
              <b-form-input
                type="text"
                placeholder="Rua"
                id="customer-profile-address-address-input"
                v-model="customer.pessoa.endereco.endereco_logradouro"
              />
            </b-form-group>

            <b-form-group
              label="Número"
              class="col-12 col-md-6 col-lg-4 col-xl-2"
              label-for="customer-profile-address-number-input"
            >
              <b-form-input
                type="text"
                placeholder="Número"
                v-mask="'#####'"
                id="customer-profile-address-number-input"
                v-model="customer.pessoa.endereco.endereco_numero"
              />
            </b-form-group> -->

            <b-form-group
              label="Estado"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              label-for="customer-profile-address-state-input"
            >
              <b-form-select
                id="customer-profile-address-state-input"
                v-model="customer.pessoa.endereco.endereco_estado"
                :options="stateOptions"
                :state="errors['endereco.endereco_estado'] ? false : null"
              />

              <b-form-invalid-feedback
                v-if="errors['endereco.endereco_estado']"
              >
                {{ errors['endereco.endereco_estado'].join(', ') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Cidade"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              label-for="customer-profile-address-city-input"
            >
              <b-form-input
                type="text"
                placeholder="Cidade"
                id="customer-profile-address-city-input"
                v-model="customer.pessoa.endereco.endereco_cidade"
              />
            </b-form-group>

            <!-- <b-form-group
              label="Complemento"
              class="col-12 col-md-6 col-lg-4 col-xl-3"
              label-for="customer-profile-address-complement-input"
            >
              <b-form-input
                type="text"
                placeholder="Complemento"
                id="customer-profile-address-complement-input"
                v-model="customer.pessoa.endereco.endereco_complemento"
              />
            </b-form-group> -->
          </template>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="customer-profile-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <div class="diagnosis-info-content">
        <div>
          <b-button @click="showInputFile = !showInputFile" variant="primary">
            <i class="bx bx-upload"></i>
            IMPOSTO DE RENDA
          </b-button>

          <div class="input-group col-sm-12" v-if="showInputFile">
            <div
              style="width: 100%; margin-bottom: 1rem; margin-left: -0.8rem"
              class="d-flex align-items-center"
            >
              <b-form-file
                browse-text="Selecionar"
                placeholder="Nome do arquivo..."
                drop-placeholder="Solte aqui..."
                v-model="file"
                style="width: 30%"
              />
              <button
                type="button"
                style="margin-left: 1rem"
                @click="() => handleFileUpload(file)"
                class="btn btn-primary"
              >
                Enviar
              </button>

              <div style="margin-left: 1rem">
                <span style="color: var(--blue)" v-if="loading"
                  >Carregando...</span
                >
                <span style="color: var(--green)" v-if="loadingSuccess"
                  >Arquivo enviado, com sucesso !</span
                >
                <span style="color: var(--red)" v-if="loadingFail"
                  >Falha ao enviar arquivo</span
                >
              </div>
            </div>
          </div>
        </div>

        <hr style="width: 100%; height: 1px; color: var(--teal)" />

        <div>
          <div>
            {{ customer.pessoa.pessoa_penultima_pag_irpf_nome }}
          </div>

          <b-button
            @click="showInputFile2 = !showInputFile2"
            variant="primary"
            style="margin: 1rem 0"
          >
            <i class="bx bx-upload"></i>
            PENÚLTIMA PÁGINA IRPF
          </b-button>

          <div class="input-group col-sm-12" v-if="showInputFile2">
            <div
              style="width: 100%; margin-bottom: 1rem; margin-left: -0.8rem"
              class="d-flex align-items-center"
            >
              <b-form-file
                browse-text="Selecionar"
                placeholder="Nome do arquivo..."
                drop-placeholder="Solte aqui..."
                v-model="file"
                style="width: 30%"
              />
              <button
                type="button"
                style="margin-left: 1rem"
                @click="() => handleFileUpload2(file)"
                class="btn btn-primary"
              >
                Enviar
              </button>

              <div style="margin-left: 1rem">
                <span style="color: var(--blue)" v-if="loading2"
                  >Carregando...</span
                >
                <span style="color: var(--green)" v-if="loadingSuccess2"
                  >Arquivo enviado, com sucesso !</span
                >
                <span style="color: var(--red)" v-if="loadingFail2"
                  >Falha ao enviar arquivo</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>
        <strong
          ><label><input type="checkbox" /></label> Declaro que solicitei a
          prévia autorização para utilização dos dados do(s) titular(es)
          conforme a base legal prevista no artigo 7º, inciso V, da LGPD</strong
        >
      </p>
    </b-card>
  </b-card>
</template>

<script>
import Vue from 'vue';
import VueMask from 'v-mask';
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-search-select/dist/VueSearchSelect.css';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import { ModelSelect } from 'vue-search-select';

import defaultData from '@/constants/defaultData';
import { cloneObject, formatData } from '@/helpers';

import {
  occupationOptions,
  sexOptions,
  comunhaoOptions,
  estadoCivilOptions,
  contactOptions,
  avaliacaoNecessidadeOptions,
  stateOptions,
  educationLevelOptions,
  workSituationOptions,
  parentescoOptions,
  tipoDocumentoIdentificacaoOptions,
  parentescoOutroDependenteOptions,
  tempoProtecaoOptions
} from '@/constants/options';

import { getAddress } from '@/services/requests/cep';
import { uploadDocumentIR } from '@/services/requests/customer';
import moment from 'moment';

Vue.use(VueMask);

export default {
  name: 'CustomerProfile',
  components: {
    VuePhoneNumberInput,
    ModelSelect,
    Money
  },
  mixins: [validationMixin],
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      occupationOptions,
      sexOptions,
      comunhaoOptions,
      estadoCivilOptions,
      contactOptions,
      avaliacaoNecessidadeOptions,
      stateOptions,
      educationLevelOptions,
      workSituationOptions,
      parentescoOptions,
      tipoDocumentoIdentificacaoOptions,
      parentescoOutroDependenteOptions,
      tempoProtecaoOptions,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },

      showInputFile: false,
      showInputFile2: false,
      file: null,
      loading: false,
      loadingSuccess: false,
      loadingFail: false,
      loading2: false,
      loadingSuccess2: false,
      loadingFail2: false
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  methods: {
    async searchAddress(cep) {
      if (!/\d{5}-\d{3}/.test(cep)) return;

      const response = await getAddress(cep);

      this.customer.pessoa.endereco.endereco_logradouro = response.logradouro;
      this.customer.pessoa.endereco.endereco_bairro = response.bairro;
      this.customer.pessoa.endereco.endereco_estado = response.uf;
      this.customer.pessoa.endereco.endereco_cidade = response.localidade;
    },

    async uploadDocumentFile(file, resource) {
      await uploadDocumentIR(
        this.customer.cliente_id,
        resource,
        await formatData('arquivo', file, file.name)
      );
    },

    handleFileUpload(file) {
      if (!file) return;
      this.loadingSuccess = false;
      this.loadingFail = false;
      this.loading = true;

      this.uploadDocumentFile(file, 'importar-xml')
        .then((response) => {
          if (!response) {
            this.loading = false;
            this.loadingSuccess = false;
            this.loadingFail = true;
          }

          if (response) {
            this.loading = false;
            this.loadingFail = false;
            this.loadingSuccess = true;
          }

          this.$router.go();
        })
        .catch(() => {
          this.loading = false;
          this.loadingFail = true;
          this.loadingSuccess = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    handleFileUpload2(file) {
      if (!file) return;
      this.loadingSuccess2 = false;
      this.loadingFail2 = false;
      this.loading2 = true;

      this.uploadDocumentFile(file, 'penultima-pag-irpf')
        .then((response) => {
          if (!response) {
            this.loading2 = false;
            this.loadingSuccess2 = false;
            this.loadingFail2 = true;
          }

          if (response) {
            this.loading2 = false;
            this.loadingFail2 = false;
            this.loadingSuccess2 = true;
          }

          const customer = response.data.result.data;
          this.customer.pessoa.pessoa_penultima_pag_irpf_path =
            customer.pessoa.pessoa_penultima_pag_irpf_path;
          this.customer.pessoa.pessoa_penultima_pag_irpf_nome =
            customer.pessoa.pessoa_penultima_pag_irpf_nome;
        })
        .catch(() => {
          this.loading2 = false;
          this.loadingFail2 = true;
          this.loadingSuccess2 = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },

    addDependent() {
      this.customer.pessoa.dependentes.push(
        cloneObject(defaultData.dependente)
      );
    },

    handleDeleteDependent(index) {
      this.customer.pessoa.dependentes.splice(index, 1);
    },

    handleDependentStudy(isStudying, dependent) {
      if (!isStudying) return;

      const birthDate = moment(dependent.data_nasc_dependente, 'YYYY-MM-DD');

      if (!birthDate.isValid()) return;

      const dependentAge = moment().diff(birthDate, 'years');

      if (dependentAge <= 0) return;
      if (dependent.estudo.tempo_restante) return;

      dependent.estudo.tempo_restante = 22 - dependentAge;
    },

    addDependentPlan() {
      this.customer.pessoa.dependentes_plano.push(
        cloneObject(defaultData.dependente_plano)
      );
    },

    handleDeleteDependentPlan(index) {
      this.customer.pessoa.dependentes_plano.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
