<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-exclamation-triangle"></i>
        <span class="ml-2"
          >Ordene suas prioridades por ordem de importância e de uma nota a cada
          uma delas</span
        >
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row
          class="align-items-center"
          v-for="(priority, index) in customer.pessoa.prioridades"
          :key="index"
        >
          <b-form-group
            label="Qual sua prioridade?"
            class="col-12 col-md-5 col-lg-3"
            :label-for="`priorities-priority-${index}-type-input`"
          >
            <b-form-select
              v-model="priority.tipo"
              :id="`priorities-priority-${index}-type-input`"
              :options="[
                ...priorityOptions.filter(
                  (item) =>
                    item.value === priority.tipo ||
                    !item.value ||
                    item.value === 6 ||
                    !customer.pessoa.prioridades.some(
                      (item2) => item2.tipo === item.value
                    )
                )
              ]"
              :state="errors[`prioridades.${index}.tipo`] ? false : null"
            />

            <b-form-invalid-feedback v-if="errors[`prioridades.${index}.tipo`]">
              {{ errors[`prioridades.${index}.tipo`].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual o nome?"
            class="col-12 col-md-5 col-lg-2"
            :label-for="`priorities-priority-${index}-name-custom-input`"
            v-if="priority.tipo === 6"
          >
            <b-form-input
              v-model="priority.nome_personalizado"
              :id="`priorities-priority-${index}-name-custom-input`"
              :options="priorityOrderOptions"
              :state="
                errors[`prioridades.${index}.nome_personalizado`] ? false : null
              "
            />

            <b-form-invalid-feedback
              v-if="errors[`prioridades.${index}.nome_personalizado`]"
            >
              {{ errors[`prioridades.${index}.nome_personalizado`].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual nota?"
            class="col-12 col-md-5 col-lg-2"
            :label-for="`priorities-priority-${index}-note-input`"
          >
            <b-form-input
              class="form-control"
              v-model="priority.nota"
              type="text"
              :id="`priorities-priority-${index}-note-input`"
              v-mask="'##'"
              :state="errors[`prioridades.${index}.nota`] ? false : null"
            />

            <b-form-invalid-feedback v-if="errors[`prioridades.${index}.nota`]">
              {{ errors[`prioridades.${index}.nota`].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-col md="6" :lg="priority.tipo === 6 ? 2 : 4" class="text-right">
            <b-button
              pill
              variant="danger"
              @click="() => handleDeletePriority(index)"
            >
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-col>
        </b-form-row>
      </div>
    </b-card>

    <!-- <b-button-group class="mb-3">
      <b-button @click="() => addPrioridade()" variant="primary">
        ADICIONAR PRIORIDADE
      </b-button>

      <b-button @click="() => addPrioridade()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group> -->

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-user-check"></i>
        <span class="ml-2"
          >Se o meu trabalho te agradou e pode gerar valor a pessoas próximas,
          podemos falar sobre algumas recomendações ?</span
        >
      </b-card-title>
    </b-card>

    <b-card
      v-for="(indication, index) in customer.pessoa.indicacoes"
      :key="index"
      class="diagnosis-info-section"
    >
      <b-card-title
        class="text-primary d-flex align-items-center justify-content-between"
      >
        <span>
          <i class="fas fa-user-check"></i>
          <span class="ml-2">Recomendação - {{ index + 1 }}</span>
        </span>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeleteIndication(index)"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Nome:"
            class="col-12 col-md-6"
            :label-for="`priorities-indication-${index}-name-input`"
          >
            <b-form-input
              class="form-control"
              :id="`priorities-indication-${index}-name-input`"
              type="text"
              v-model="indication.nome"
            />
          </b-form-group>

          <b-form-group
            label="E-mail:"
            class="col-12 col-md-6"
            :label-for="`priorities-indication-${index}-email-input`"
          >
            <b-form-input
              class="form-control"
              :id="`priorities-indication-${index}-email-input`"
              type="email"
              v-model="indication.email"
              :state="errors[`indicacoes.${index}.email`] ? false : null"
            />

            <b-form-invalid-feedback v-if="errors[`indicacoes.${index}.email`]">
              {{ errors[`indicacoes.${index}.email`].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            id="telephone-input-group"
            label="Telefone"
            class="col-12 col-md-6 col-lg-6"
            :label-for="`priorities-indication-${index}-telephone-input`"
          >
            <VuePhoneNumberInput
              v-model="indication.telefone"
              :translations="numberInputTranslations"
              :id="`priorities-indication-${index}-telephone-input`"
            />
          </b-form-group>

          <b-form-group
            label="Número de dependentes:"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`priorities-indication-${index}-dependents-input`"
          >
            <b-form-input
              class="form-control"
              :id="`priorities-indication-${index}-dependents-input`"
              type="number"
              v-model="indication.numero_dependentes"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-button-group class="mb-3">
      <b-button @click="() => addIndicacao()" variant="primary">
        ADICIONAR RECOMENDAÇÃO
      </b-button>

      <b-button @click="() => addIndicacao()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="priorities-observation-textarea"
            rows="5"
            v-model="customer.pessoa.observacao_prioridades"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';

import defaultData from '@/constants/defaultData';
import { priorityOrderOptions, priorityOptions } from '@/constants/options';
import { cloneObject } from '@/helpers';

export default {
  name: 'FamilyProfile',
  components: { VuePhoneNumberInput },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      priorityOrderOptions,
      priorityOptions
    };
  },
  mounted() {
    const rest = 5 - this.customer.pessoa.prioridades.length;
    const fakeArray = Array.from({ length: rest });

    fakeArray.forEach(() => {
      return this.addPrioridade();
    });
  },

  methods: {
    addPrioridade() {
      this.customer.pessoa.prioridades.push(
        cloneObject(defaultData.prioridade)
      );
    },
    addIndicacao() {
      this.customer.pessoa.indicacoes.push(cloneObject(defaultData.indicacao));
    },
    handleDeletePriority(index) {
      this.customer.pessoa.prioridades.splice(index, 1);
    },
    handleDeleteIndication(index) {
      this.customer.pessoa.indicacoes.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
