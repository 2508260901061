<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="bx bx-cloud"></i>
        <span class="ml-2">Sonhos</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-top">
          <b-form-group
            label="Possui algum sonho ou plano financeiro a longo prazo para realizar?"
            class="col-12 col-md-6 col-xl-6"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_possui_sonho"
              :options="yesOrNotOptions"
            />
          </b-form-group>
          <i
            v-b-tooltip.hover
            title="compra de imoveis, terreno, veiculos, etc"
            class="bx bx-help-circle"
            style="font-size: 1.2rem"
          />
        </b-form-row>

        <b-form-group
          label="Gostaria de comprar algum imóvel no exterior para diversificar seu investimento e gerar rentabilidade em Dólar ou Euro?"
          class="col-12"
          style="position: relative"
        >
          <i
            v-b-tooltip.hover
            title="Direcionar esta pergunta somente para investidores com capital no exterior e/ou investimentos acima de R$ 2 milhões"
            class="bx bx-help-circle"
            style="
              font-size: 1.2rem;
              position: absolute;
              top: -1.8rem;
              right: 5rem;
            "
          ></i>
          <b-form-radio-group
            v-model="customer.pessoa.pessoa_plano.plano_comprar_imovel_exterior"
            :options="yesOrNotOptions"
          />
        </b-form-group>
      </div>
    </b-card>

    <div class="text-left mb-3" v-show="customer.pessoa.pessoa_possui_sonho">
      <b-button-group>
        <b-button @click="() => addDream()" variant="primary">
          ADICIONAR SONHO
        </b-button>

        <b-button @click="() => addDream()" variant="dark-primary">
          <i class="bx bx-plus" style="font-size: 0.95rem"></i>
        </b-button>
      </b-button-group>
    </div>

    <div v-show="customer.pessoa.pessoa_possui_sonho">
      <b-card
        v-for="(dream, index) in customer.pessoa.sonhos"
        :key="index"
        class="diagnosis-info-section"
      >
        <b-card-title
          class="text-primary d-flex align-items-center justify-content-between"
        >
          <span>
            <i class="bx bx-cloud"></i>
            <span class="ml-2">Sonho {{ index + 1 }}</span>
          </span>

          <b-button
            pill
            variant="danger"
            @click="() => handleDeleteDream(index)"
          >
            <i class="fas fa-trash-alt" />
          </b-button>
        </b-card-title>

        <div class="diagnosis-info-content">
          <b-form-row class="align-items-end">
            <b-form-group
              label="Qual seu sonho?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-name-input`"
            >
              <b-form-input
                class="form-control"
                placeholder="Ex: Casa na praia"
                v-model="dream.nome"
                :id="`plans-and-dreams-dream-${index}-name-input`"
              />
            </b-form-group>

            <b-form-group
              label="Tipo"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-tipo-input`"
            >
              <b-form-select
                :id="`plans-and-dreams-dream-${index}-tipo-input`"
                v-model="dream.tipo"
                :options="dreamTypeOptions"
              />
            </b-form-group>

            <b-form-group
              label="Qual o tipo?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-tipo-input`"
              v-if="dream.tipo === 'Outros'"
            >
              <b-form-input
                class="form-control"
                v-model="dream.tipo_customizado"
                :id="`plans-and-dreams-dream-${index}-tipo-customizado-input`"
              />
            </b-form-group>

            <b-form-group
              label="Qual o valor?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-valor-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="dream.valor"
                :id="`plans-and-dreams-dream-${index}-tipo-input`"
              />
            </b-form-group>

            <b-form-group
              label="Para quando?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-ano-input`"
            >
              <b-form-select
                :id="`plans-and-dreams-dream-${index}-ano-input`"
                v-model="dream.para_quando"
                :options="dreamYearOptions"
              />

              <b-form-invalid-feedback v-if="errors[`sonhos.${index}.ano`]">
                {{ errors[`sonhos.${index}.ano`].join(', ') }}
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              label="Qual o valor de entrada ou de lance?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`plans-and-dreams-dream-${index}-valor-contruido-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="dream.valor_construido"
                :id="`plans-and-dreams-dream-${index}-valor-contruido-input`"
              />
            </b-form-group>
          </b-form-row>
        </div>
      </b-card>
    </div>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="plans-and-dreams-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_plano.plano_observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';
import { dreamTypeOptions, dreamYearOptions } from '@/constants/options';
import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      dreamTypeOptions,
      dreamYearOptions
    };
  },
  methods: {
    addDream() {
      this.customer.pessoa.sonhos.push(cloneObject(defaultData.sonho));
    },
    handleDeleteDream(index) {
      this.customer.pessoa.sonhos.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
