<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-user-times"></i>
        <span class="ml-2">
          Vamos falar sobre gestão de riscos com perda de renda
        </span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Você depende financeiramente do seu trabalho para gerar renda?"
            class="col-12"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_plano.planob_depende_trabalho"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Voce acredita que suas reservas financeiras sao suficientes para suprir as necessidades da sua familia na sua ausencia?"
            class="col-12"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_plano.planob_reservas_suficientes"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Você possui alguma rotina que aumente a sua exposição ao risco?"
            :class="`col-12 ${
              customer.pessoa.pessoa_plano.planob_possui_rotina_exposicao_risco
                ? 'col-md-6'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_plano
                  .planob_possui_rotina_exposicao_risco
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual?"
            class="col-12 col-md-6"
            label-for="planb-qual-risco-input"
            v-show="
              customer.pessoa.pessoa_plano.planob_possui_rotina_exposicao_risco
            "
          >
            <b-form-input
              class="form-control"
              id="planb-qual-risco-input"
              type="text"
              v-model="
                customer.pessoa.pessoa_plano.planob_rotina_exposicao_risco
              "
            />
          </b-form-group>

          <b-form-group
            label="Você possui motocicleta?"
            :class="`col-12 ${
              customer.pessoa.pessoa_plano.planob_possui_motocicleta
                ? 'col-md-6 col-lg-4'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_plano.planob_possui_motocicleta"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual cilindrada?"
            class="col-12 col-md-6 col-lg-4"
            label-for="planb-cilindrada-input"
            v-show="customer.pessoa.pessoa_plano.planob_possui_motocicleta"
          >
            <b-form-select
              id="planb-cilindrada-input"
              v-model="
                customer.pessoa.pessoa_plano.planob_motocicleta_cilindrada
              "
              :options="cilindradaOptions"
            />
          </b-form-group>

          <b-form-group
            label="Utiliza para qual finalidade?"
            class="col-12 col-md-6 col-lg-4"
            label-for="planb-finalidade-input"
            v-show="customer.pessoa.pessoa_plano.planob_possui_motocicleta"
          >
            <b-form-input
              class="form-control"
              id="planb-finalidade-input"
              type="text"
              v-model="
                customer.pessoa.pessoa_plano.planob_moticicleta_finalidade
              "
            />
          </b-form-group>

          <b-form-group
            label="Você possui algum seguro de vida?"
            :class="`col-12 ${
              customer.pessoa.pessoa_plano.planob_possui_seguro_vida
                ? 'col-md-12'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_plano.planob_possui_seguro_vida"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="A pessoa responsável pelo pagamento é politicamente exposta?"
            class="col-12 col-md-6"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_plano.planob_pessoa_politicamente_exposta
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <div v-show="customer.pessoa.pessoa_plano.planob_possui_seguro_vida">
      <b-card
        v-for="(safeItem, index) in customer.pessoa.seguro_itens"
        :key="index"
        class="diagnosis-info-section"
      >
        <b-card-title class="text-primary">
          <i class="fas fa-address-card"></i>
          <span class="ml-2"> Seguro - {{ index + 1 }}</span>
        </b-card-title>

        <div class="diagnosis-info-content">
          <b-form-row class="align-items-end">
            <b-form-group
              label="Seguradora"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-seguradora-input`"
            >
              <b-form-select
                :id="`planb-safe-item-${index}-seguradora-input`"
                v-model="safeItem.seguradora"
                :options="insurersOptions"
              />
            </b-form-group>

            <b-form-group
              label="Qual a outra seguradora?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-seguradora-outra-input`"
              v-show="safeItem.seguradora === 'Outra'"
            >
              <b-form-input
                class="form-control"
                :id="`planb-safe-item-${index}-seguradora-outra-input`"
                type="text"
                v-model="safeItem.seguradora_outra"
              />
            </b-form-group>

            <b-form-group
              label="Tipo de seguro"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-seguradora-input`"
            >
              <b-form-select
                :id="`planb-safe-item-${index}-tipo-seguro-input`"
                v-model="safeItem.tipo_seguro"
                :options="tipoSeguroOptions"
              />
            </b-form-group>

            <b-form-group
              label="Por mais quanto tempo?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-seguradora-outra-input`"
              v-show="
                ['Temporário', 'Temporário decrescente'].includes(
                  safeItem.tipo_seguro
                )
              "
            >
              <b-form-input
                class="form-control"
                :id="`planb-safe-item-${index}-seguradora-outra-input`"
                type="text"
                v-model="safeItem.quanto_tempo"
                v-mask="'###'"
              />
            </b-form-group>

            <b-form-group
              label="Valor mensal"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-valor-mensal-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="safeItem.valor_mensal"
                :id="`planb-safe-item-${index}-valor-mensal-input`"
              />
            </b-form-group>

            <b-form-group
              label="Valor da cobertura?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-valor-cobertura-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="safeItem.valor_cobertura"
                :id="`planb-safe-item-${index}-valor-cobertura-input`"
              />
            </b-form-group>

            <b-form-group
              label="Pessoa física ou jurídica?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-fisica-juridica-input`"
            >
              <b-form-select
                :id="`planb-safe-item-${index}-fisica-juridica-input`"
                v-model="safeItem.tipo_pessoa"
                :options="tipoPessoaOptions"
              />
            </b-form-group>

            <b-form-group label="Resgatavel" class="col-12 col-md-6 col-lg-4">
              <b-form-radio-group
                v-model="safeItem.resgatavel"
                :options="yesOrNotOptions"
              />
            </b-form-group>

            <b-col mb="6" class="text-right mb-lg-3">
              <b-button
                pill
                variant="danger"
                @click="() => handleDeleteSeguro(index)"
              >
                <i class="fas fa-trash-alt" />
              </b-button>
            </b-col>

            <b-col cols="12">
              <div class="input-group col-sm-12">
                <div>
                  Apólice
                  {{ safeItem.apolice_nome || '(Não enviado)' }}
                </div>

                <div
                  style="width: 100%; margin-bottom: 1rem; margin-left: -0.8rem"
                  class="d-flex align-items-center"
                >
                  <b-form-file
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    v-model="safeItem.file"
                    style="width: 30%"
                  />

                  <button
                    type="button"
                    style="margin-left: 1rem"
                    @click="() => handleFileUploadSeguro(safeItem, index)"
                    class="btn btn-primary"
                  >
                    Enviar
                  </button>

                  <div style="margin-left: 1rem">
                    <span
                      style="color: var(--blue)"
                      v-if="loadingSeguro[safeItem.seguro_item_id]"
                    >
                      Carregando...
                    </span>

                    <span
                      style="color: var(--green)"
                      v-if="loadingSuccessSeguro[safeItem.seguro_item_id]"
                    >
                      Arquivo enviado, com sucesso !
                    </span>

                    <span
                      style="color: var(--red)"
                      v-if="loadingFailSeguro[safeItem.seguro_item_id]"
                    >
                      Falha ao enviar arquivo
                    </span>
                  </div>
                </div>

                <div
                  v-if="safeItem.download_url"
                  class="d-flex justify-content-between align-items-center"
                >
                  <a :href="safeItem.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    class="ml-2"
                    @click="() => handleDeleteFileSafeItem(safeItem, index)"
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-form-row>
        </div>
      </b-card>
    </div>

    <b-button-group
      class="mb-3"
      v-show="customer.pessoa.pessoa_plano.planob_possui_seguro_vida"
    >
      <b-button @click="() => addSeguro()" variant="primary">
        ADICIONAR SEGURO
      </b-button>

      <b-button @click="() => addSeguro()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-user-plus"></i>
        <span class="ml-2">Vamos falar sobre a gestão de riscos em vida</span>
      </b-card-title>

      <div
        class="diagnosis-info-content"
        v-for="(safeGroup, index) in customer.pessoa.grupo_seguros"
        :key="index"
      >
        <template v-if="safeGroup.nome != 'Gestão de risco na morte'">
          <hr v-if="index > 0" />

          <p>
            Possui algum seguro para
            <span style="text-transform: lowercase">{{ safeGroup.nome }}</span>
            ?
          </p>

          <b-form-row
            class="align-items-end"
            v-for="(safeGroupItem, indexItem) in safeGroup.grupo_seguro_itens"
            :key="indexItem"
          >
            <b-col cols="12" v-if="indexItem > 0">
              <hr />
            </b-col>

            <b-form-group
              label="Seguradora"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-group-${index}-item-${indexItem}-seguradora-input`"
            >
              <b-form-select
                class="form-control"
                :id="`planb-group-${index}-item-${indexItem}-seguradora-input`"
                v-model="safeGroupItem.seguradora"
                :options="insurersOptions"
              />
            </b-form-group>

            <b-form-group
              label="Qual a outra seguradora?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-group-${index}-item-${indexItem}-seguradora-outra-input`"
              v-show="safeGroupItem.seguradora === 'Outra'"
            >
              <b-form-input
                class="form-control"
                :id="`planb-group-${index}-item-${indexItem}-seguradora-outra-input`"
                type="text"
                v-model="safeGroupItem.seguradora_outra"
              />
            </b-form-group>

            <b-form-group
              label="Tipo de seguro"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-safe-item-${index}-seguradora-input`"
            >
              <b-form-select
                :id="`planb-group-${index}-item-${indexItem}-seguradora-input`"
                v-model="safeGroupItem.tipo_seguro"
                :options="[
                  { value: null, text: 'Selecione' },
                  ...safeGroup.opcoes_tipo_seguro.filter(
                    (item) =>
                      item.value === safeGroupItem.tipo_seguro ||
                      !safeGroup.grupo_seguro_itens.some(
                        (item2) => item2.tipo_seguro === item.value
                      )
                  )
                ]"
              />
            </b-form-group>

            <b-form-group
              label="Valor mensal"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`planb-group-${index}-item-${indexItem}-valor-mensal-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="safeGroupItem.valor_mensal"
                :id="`planb-group-${index}-item-${indexItem}-valor-mensal-input`"
              />
            </b-form-group>

            <b-form-group
              label="Valor da cobertura?"
              class="col-12 col-md-6 col-lg-3"
              :label-for="`planb-group-${index}-item-${indexItem}-valor-cobertura-input`"
            >
              <money
                class="form-control"
                v-bind="money"
                v-model="safeGroupItem.valor_cobertura"
                :id="`planb-group-${index}-item-${indexItem}-valor-cobertura-input`"
              />
            </b-form-group>

            <b-form-group
              label="Pessoa física ou jurídica?"
              class="col-12 col-md-6 col-lg-4"
              :label-for="`planb-group-${index}-item-${indexItem}-fisica-juridica-input`"
            >
              <b-form-select
                :id="`planb-group-${index}-item-${indexItem}-fisica-juridica-input`"
                v-model="safeGroupItem.tipo_pessoa"
                :options="tipoPessoaOptions"
              />
            </b-form-group>

            <b-col mb="6" lg="2" class="text-right mb-lg-3">
              <b-button
                pill
                variant="danger"
                @click="() => handleDeleteSafeGroupItem(safeGroup, indexItem)"
              >
                <i class="fas fa-trash-alt" />
              </b-button>
            </b-col>

            <b-col cols="12">
              <div class="input-group col-sm-12">
                <div>
                  Apólice
                  {{ safeGroupItem.apolice_nome || '(Não enviado)' }}
                </div>

                <div
                  style="width: 100%; margin-left: -0.8rem"
                  class="d-flex align-items-center"
                >
                  <b-form-file
                    browse-text="Selecionar"
                    placeholder="Nome do arquivo..."
                    drop-placeholder="Solte aqui..."
                    v-model="safeGroupItem.file"
                    style="width: 30%"
                  />
                  <button
                    type="button"
                    style="margin-left: 1rem"
                    @click="
                      () => handleFileUpload(safeGroupItem, index, indexItem)
                    "
                    class="btn btn-primary"
                  >
                    Enviar
                  </button>

                  <div style="margin-left: 1rem">
                    <span
                      style="color: var(--blue)"
                      v-if="loading[safeGroupItem.grupo_seguro_item_id]"
                    >
                      Carregando...
                    </span>
                    <span
                      style="color: var(--green)"
                      v-if="loadingSuccess[safeGroupItem.grupo_seguro_item_id]"
                    >
                      Arquivo enviado, com sucesso !
                    </span>
                    <span
                      style="color: var(--red)"
                      v-if="loadingFail[safeGroupItem.grupo_seguro_item_id]"
                    >
                      Falha ao enviar arquivo
                    </span>
                  </div>
                </div>

                <div
                  v-if="safeGroupItem.download_url"
                  class="d-flex justify-content-between align-items-center mt-2 mb-4"
                >
                  <a :href="safeGroupItem.download_url" target="_blank">
                    Visualizar
                  </a>

                  <b-button
                    pill
                    variant="danger"
                    class="ml-2"
                    @click="
                      () =>
                        handleDeleteFileSafeGroupItem(
                          safeGroupItem,
                          index,
                          indexItem
                        )
                    "
                  >
                    <i class="fas fa-trash-alt" />
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-form-row>

          <b-button-group class="mb-3">
            <b-button
              @click="() => addSafeGroupItem(safeGroup)"
              variant="primary"
            >
              ADICIONAR SEGURO
            </b-button>

            <b-button
              @click="() => addSafeGroupItem(safeGroup)"
              variant="dark-primary"
            >
              <i class="bx bx-plus" style="font-size: 0.95rem"></i>
            </b-button>
          </b-button-group>
        </template>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="planb-b-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_plano.planob_observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';

import {
  tipoSeguroOptions,
  tipoPessoaOptions,
  cilindradaOptions
} from '@/constants/options';

import { cloneObject } from '@/helpers';
import { mapGetters } from 'vuex';

import {
  uploadSecurityItem,
  uploadGroupSecurityItem,
  deleteSafeItemFile,
  deleteSafeGroupItemFile
} from '@/services/requests/diagnosis';

export default {
  name: 'FamilyProfile',
  mixins: [validationMixin],
  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object,
    updateCustomer: Function
  },
  computed: {
    ...mapGetters('diagnosis', ['insurersOptions'])
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      tipoSeguroOptions,
      tipoPessoaOptions,
      cilindradaOptions,
      file: null,
      loading: {},
      loadingSuccess: {},
      loadingFail: {},
      loading2: {},
      loadingSuccess2: {},
      loadingFail2: {},
      loadingSeguro: {},
      loadingSuccessSeguro: {},
      loadingFailSeguro: {}
    };
  },
  methods: {
    addSeguro() {
      this.customer.pessoa.seguro_itens.push(
        cloneObject(defaultData.seguro_item)
      );
    },
    handleDeleteSeguro(index) {
      this.customer.pessoa.seguro_itens.splice(index, 1);
    },
    addSafeGroupItem(safeGroup) {
      safeGroup.grupo_seguro_itens.push(
        cloneObject(defaultData.grupo_seguro_item)
      );
    },
    handleDeleteSafeGroupItem(safeGroup, index) {
      safeGroup.grupo_seguro_itens.splice(index, 1);
    },
    async uploadDocumentFileSeguro(safeItem, index) {
      const { seguro_item_id: id, file } = safeItem;

      if (!file) return;

      this.$set(this.loadingSuccess, id, false);
      this.$set(this.loadingFail, id, false);
      this.$set(this.loading, id, true);

      uploadSecurityItem(id, file, file.name)
        .then((response) => {
          if (!response) {
            this.$set(this.loading, id, false);
            this.$set(this.loadingSuccess, id, false);
            this.$set(this.loadingFail, id, true);
            return;
          }

          this.$set(this.loading, id, false);
          this.$set(this.loadingFail, id, false);
          this.$set(this.loadingSuccess, id, true);

          const updatedsafeItem = response.data.result.data;

          this.$set(this.customer.pessoa.seguro_itens, index, updatedsafeItem);
        })
        .catch(() => {
          this.$set(this.loading, id, false);
          this.$set(this.loadingFail, id, true);
          this.$set(this.loadingSuccess, id, false);
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    async uploadDocumentFile(safeGroupItem, index, indexItem) {
      const { grupo_seguro_item_id: id, file } = safeGroupItem;

      if (!file) return;

      this.$set(this.loadingSuccess, id, false);
      this.$set(this.loadingFail, id, false);
      this.$set(this.loading, id, true);

      uploadGroupSecurityItem(id, file, file.name)
        .then((response) => {
          if (!response) {
            this.$set(this.loading, id, false);
            this.$set(this.loadingSuccess, id, false);
            this.$set(this.loadingFail, id, true);
            return;
          }

          this.$set(this.loading, id, false);
          this.$set(this.loadingFail, id, false);
          this.$set(this.loadingSuccess, id, true);

          const updatedSafeGroupItem = response.data.result.data;

          this.customer.pessoa.grupo_seguros[index].grupo_seguro_itens[
            indexItem
          ] = {
            ...this.customer.pessoa.grupo_seguros[index].grupo_seguro_itens[
              indexItem
            ],
            ...updatedSafeGroupItem
          };
        })
        .catch(() => {
          this.$set(this.loading, id, false);
          this.$set(this.loadingFail, id, true);
          this.$set(this.loadingSuccess, id, false);
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    async handleFileUploadSeguro(safeItem, index) {
      const { seguro_item_id: id, file } = safeItem;

      if (!file) return;

      if (id) {
        return this.uploadDocumentFileSeguro(safeItem, index);
      }

      this.updateCustomerMethod((data) => {
        safeItem.seguro_item_id =
          data.pessoa.seguro_itens[index].seguro_item_id;

        this.uploadDocumentFileSeguro(safeItem, index);
      });
    },
    async handleFileUpload(safeGroupItem, index, indexItem) {
      const { grupo_seguro_item_id: id, file } = safeGroupItem;

      if (!file) return;

      if (id) {
        return this.uploadDocumentFile(safeGroupItem, index, indexItem);
      }

      this.updateCustomerMethod((data) => {
        safeGroupItem.grupo_seguro_item_id =
          data.pessoa.grupo_seguros[index].grupo_seguro_itens[
            indexItem
          ].grupo_seguro_item_id;

        this.uploadDocumentFile(safeGroupItem, index, indexItem);
      });
    },
    updateCustomerMethod(onSuccess) {
      this.updateCustomer(onSuccess);
    },
    handleDeleteFileSafeItem(safeItem, safeItemIndex) {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteSafeItemFile(safeItem.seguro_item_id)
        .then((res) => {
          this.$set(
            this.customer.pessoa.seguro_itens,
            safeItemIndex,
            res.data.result.data
          );
        })
        .catch(() => {
          alert('Houve um erro ao excluir o arquivo');
        });
    },
    handleDeleteFileSafeGroupItem(
      safeGroupItem,
      safeGroupIndex,
      safeGroupItemIndex
    ) {
      if (!confirm('Deseja realmente excluir este arquivo?'));

      deleteSafeGroupItemFile(safeGroupItem.grupo_seguro_item_id)
        .then((res) => {
          this.$set(
            this.customer.pessoa.grupo_seguros[safeGroupIndex]
              .grupo_seguro_itens,
            safeGroupItemIndex,
            res.data.result.data
          );
        })
        .catch(() => {
          alert('Houve um erro ao excluir o arquivo');
        });
    }
  },
  watch: {}
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
