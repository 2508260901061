<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-home"></i>
        <span class="ml-2">Patrimônios</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Possui algum bem fisico?"
            class="col-12 col-md-6 col-xl-6"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.patrimonio_investimento.possui_patrimonio_fisico
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Tem empréstimo utilizando imóvel como garantia?"
            class="col-12 col-md-6 col-xl-6"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.patrimonio_investimento.emprest_imovel_garantia
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      v-for="(patrimony, index) in customer.pessoa.patrimonio_investimento
        .patrimonio_itens"
      :key="`patrimony-${index}`"
      class="diagnosis-info-section"
    >
      <b-card-title
        class="text-primary d-flex align-items-center justify-content-between"
      >
        <span>
          <i class="fas fa-user-circle"></i>
          <span class="ml-2">Bem físico {{ index + 1 }}</span>
        </span>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeletePatrimony(index)"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual é o seu patrimônio?"
            class="col-12 col-md-6 col-lg-3"
            :label-for="`equity-investments-patrimony-${index}-name-input`"
          >
            <b-form-input
              v-model="patrimony.nome"
              :id="`equity-investments-patrimony-${index}-name-input`"
            />
          </b-form-group>

          <b-form-group
            label="Tipo do bem"
            class="col-12 col-md-6 col-lg-3"
            :label-for="`equity-investments-patrimony-${index}-type-input`"
          >
            <b-form-select
              v-model="patrimony.tipo"
              :options="tipoBemOptions"
              :id="`equity-investments-patrimony-${index}-type-input`"
            />
          </b-form-group>

          <b-form-group
            label="Valor do bem"
            class="col-12 col-md-6 col-lg-3"
            :label-for="`equity-investments-patrimony-${index}-valor-input`"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="patrimony.valor"
              :id="`equity-investments-patrimony-${index}-valor-input`"
            />
          </b-form-group>

          <b-form-group label="Está quitado?" class="col-12 col-md-6 col-lg-3">
            <b-form-radio-group
              v-model="patrimony.quitado"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            style="position: relative"
            label="Pode ser vendido?"
            class="col-12 col-md-6 col-lg-2"
          >
            <i
              v-b-tooltip.hover
              title="Imóvel que pode ser vendido para compor o montante para a aposentadoria."
              class="bx bx-help-circle"
              style="
                font-size: 1.2rem;
                position: absolute;
                top: -1.7rem;
                left: 130px;
              "
            />
            <b-form-radio-group
              v-model="patrimony.pode_ser_vendido"
              :options="yesOrNotOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-button-group
      v-if="customer.pessoa.patrimonio_investimento.possui_patrimonio_fisico"
    >
      <b-button @click="() => addPatrimony()" variant="primary">
        ADICIONAR BEM
      </b-button>
      <b-button @click="() => addPatrimony()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <b-card class="diagnosis-info-section" style="margin-top: 1.5rem">
      <b-card-title class="text-primary">
        <i class="fas fa-dollar-sign"></i>
        <span class="ml-2">Investimentos</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual o seu perfil de investidor?"
            class="col-12 col-md-6 col-lg-4"
            label-for="`equity-investments-perfil-investidor-input"
            style="position: relative"
          >
            <i
              v-b-tooltip.hover
              title="Caso seu cliente não possua um perfil de investidor definido, direcioná-lo para o teste de Suitability Fiduc."
              class="bx bx-help-circle"
              style="font-size: 1.2rem; position: absolute; top: 0; left: 220px"
            />

            <b-form-select
              v-model="
                customer.pessoa.patrimonio_investimento.perfil_investidor
              "
              :options="perfilInvestidorOptions"
              id="equity-investments-perfil-investidor-input"
            />
          </b-form-group>
        </b-form-row>
      </div>
      <b-col
        v-show="
          customer.pessoa.patrimonio_investimento.investimento_itens.length <= 0
        "
        cols="12"
        class="pl-0"
      >
        <b-button-group>
          <b-button @click="() => addInvestiment()" variant="primary">
            INSERIR INVESTIMENTO
          </b-button>
          <b-button @click="() => addInvestiment()" variant="dark-primary">
            <i class="bx bx-plus" style="font-size: 0.95rem"></i>
          </b-button>
        </b-button-group>
      </b-col>
    </b-card>

    <b-card
      v-for="(item, index) in customer.pessoa.patrimonio_investimento
        .investimento_itens"
      :key="`investiment-${index}`"
      class="diagnosis-info-section"
    >
      <b-card-title
        class="text-primary d-flex align-items-center justify-content-between"
      >
        <span>
          <i class="fas fa-user-circle"></i>
          <span class="ml-2">Investimento {{ index + 1 }}</span>
        </span>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeleteInvestiment(index)"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Nome do investimento"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-name-input`"
          >
            <b-form-input
              placeholder="Nome"
              v-model="item.nome"
              :id="`equity-investments-patrimony-${index}-name-input`"
            />
          </b-form-group>

          <b-form-group
            label="Tipo de investimento"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-type-input`"
          >
            <b-form-select
              :id="`equity-investments-patrimony-${index}-type-input`"
              :options="tipoInvestimentoOptions"
              type="text"
              v-model="item.tipo"
            />
          </b-form-group>

          <b-form-group
            label="Valor:"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-valor-input`"
          >
            <money
              :id="`equity-investments-patrimony-${index}-valor-input`"
              class="form-control"
              v-bind="money"
              v-model="item.valor"
            />
          </b-form-group>

          <b-form-group
            label="Qual a rentabilidade média?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-rentabilidade-input`"
          >
            <money
              :id="`equity-investments-patrimony-${index}-rentabilidade-input`"
              class="form-control"
              placeholder="Ex: 15%"
              v-bind="percentage"
              v-model="item.rentabilidade"
            />
          </b-form-group>

          <b-form-group
            label="Liquidez"
            class="col-12 col-md-6 col-lg-8"
            :label-for="`equity-investments-patrimony-${index}-liquidez-input`"
          >
            <b-form-select
              v-model="item.liquidez"
              :options="liquidezOptions"
              :id="`equity-investments-patrimony-${index}-liquidez-input`"
            />
          </b-form-group>

          <b-form-group
            label="Faz aportes regularmente?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="item.faz_aportes_regularmente"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual valor?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-liquidez-input`"
            v-slot="{ ariaDescribedby }"
            v-show="item.faz_aportes_regularmente"
          >
            <money
              class="form-control"
              v-bind="money"
              :id="`equity-investments-patrimony-${index}-liquidez-input`"
              v-model="item.valor_aportes"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>

          <b-form-group
            label="Com qual frequencia faz aportes?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-aportes-input`"
            v-show="item.faz_aportes_regularmente"
          >
            <b-form-select
              v-model="item.frequencia_aportes"
              :options="aportesOptions"
              :id="`equity-investments-patrimony-${index}-aportes-input`"
            />
          </b-form-group>

          <b-form-group
            v-if="
              item.faz_aportes_regularmente && item.frequencia_aportes === 5
            "
            label="Outra frequencia aportes"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-aportes-input`"
          >
            <b-form-input
              placeholder="Outros aportes"
              v-model="item.frequencia_aportes_customizada"
              :id="`equity-investments-patrimony-${index}-outros-aportes-input`"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-col
      v-show="
        customer.pessoa.patrimonio_investimento.investimento_itens.length > 0
      "
      cols="12"
      class="pl-0 mb-4"
    >
      <b-button-group>
        <b-button @click="() => addInvestiment()" variant="primary">
          INSERIR INVESTIMENTO
        </b-button>
        <b-button @click="() => addInvestiment()" variant="dark-primary">
          <i class="bx bx-plus" style="font-size: 0.95rem"></i>
        </b-button>
      </b-button-group>
    </b-col>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="equity-investments-observation-textarea"
            rows="5"
            v-model="customer.pessoa.patrimonio_investimento.observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';

import {
  tipoInvestimentoOptions,
  perfilInvestidorOptions,
  tipoBemOptions,
  liquidezOptions,
  aportesOptions
} from '@/constants/options';

import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  mixins: [validationMixin],
  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      percentage: {
        decimal: ',',
        thousands: '.',
        suffix: ' %',
        precision: 2,
        masked: false
      },
      tipoInvestimentoOptions,
      perfilInvestidorOptions,
      tipoBemOptions,
      liquidezOptions,
      aportesOptions
    };
  },
  methods: {
    addPatrimony() {
      this.customer.pessoa.patrimonio_investimento.patrimonio_itens.push(
        cloneObject(defaultData.patrimonio_item)
      );
    },
    handleDeletePatrimony(index) {
      this.customer.pessoa.patrimonio_investimento.patrimonio_itens.splice(
        index,
        1
      );
    },
    addInvestiment() {
      this.customer.pessoa.patrimonio_investimento.investimento_itens.push(
        cloneObject(defaultData.investimento_item)
      );
    },
    handleDeleteInvestiment(index) {
      this.customer.pessoa.patrimonio_investimento.investimento_itens.splice(
        index,
        1
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
