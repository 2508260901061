<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-heartbeat"></i>
        <span class="ml-2"
          >Vamos falar sobre os riscos de gastos com a sua saúde</span
        >
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual sua altura?"
            class="col-12 col-md-6 col-lg-2"
            label-for="health-expenditure-risk-altura-input"
          >
            <money
              id="health-expenditure-risk-altura-input"
              class="form-control"
              v-bind="height"
              v-model="customer.pessoa.pessoa_saude.altura"
            />
          </b-form-group>

          <b-form-group
            label="Qual seu peso?"
            class="col-12 col-md-6 col-lg-2"
            label-for="health-expenditure-risk-peso-input"
            type="number"
          >
            <money
              id="health-expenditure-risk-peso-input"
              class="form-control"
              v-bind="weight"
              v-model="customer.pessoa.pessoa_saude.peso"
            />
          </b-form-group>

          <b-form-group
            label="Fumante?"
            class="col-12 col-md-6 col-lg-2"
            label-for="health-expenditure-risk-fumante-input"
          >
            <b-form-select
              id="health-expenditure-risk-fumante-input"
              v-model="customer.pessoa.pessoa_saude.fumante"
              :options="fumanteOptions"
            />
          </b-form-group>

          <b-form-group
            label="Se ex fumante, em qual ano parou?"
            class="col-12 col-md-6 col-lg-3"
            label-for="health-expenditure-risk-ano_parou_fumar_input"
          >
            <b-form-input
              type="text"
              v-mask="'####'"
              class="form-control"
              id="health-expenditure-risk-ano_parou_fumar_input"
              v-model="customer.pessoa.pessoa_saude.ano_parou_fumar"
              :state="errors['pessoa_saude.ano_parou_fumar'] ? false : null"
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_saude.ano_parou_fumar']"
            >
              {{ errors['pessoa_saude.ano_parou_fumar'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            label="Qual foi a última vez que foi ao médico?"
            class="col-12 col-md-6 col-lg-3"
            label-for="health-expenditure-risk-ultima_vez_medico"
            :state="errors['pessoa_saude.ultima_consulta'] ? false : null"
          >
            <b-form-input
              v-model="customer.pessoa.pessoa_saude.ultima_consulta"
              type="date"
              id="customer-ultima-consulta"
            />

            <b-form-invalid-feedback
              v-if="errors['pessoa_saude.ultima_consulta']"
            >
              {{ errors['pessoa_saude.ultima_consulta'].join(', ') }}
            </b-form-invalid-feedback>
          </b-form-group>

          <hr style="width: 100%" />

          <b-form-group
            label="Toma algum remédio controlado?"
            :class="`col-12 col-md-6 col-lg-${
              customer.pessoa.pessoa_saude.toma_remedio_controlado ? '4' : '12'
            }`"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_saude.toma_remedio_controlado"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-col
            cols="12"
            v-show="customer.pessoa.pessoa_saude.toma_remedio_controlado"
          >
            <b-form-row
              v-for="(remedio, index) in customer.pessoa.pessoa_remedios"
              :key="index"
            >
              <b-form-group
                label="Qual o nome?"
                class="col-12 col-md-5"
                label-for="health-expenditure-risk-remedio_controlado"
                v-show="customer.pessoa.pessoa_saude.toma_remedio_controlado"
              >
                <b-form-input
                  class="form-control"
                  v-model="remedio.nome"
                  type="text"
                  id="health-expenditure-risk-remedio_controlado"
                />
              </b-form-group>

              <b-form-group
                label="Qual a dosagem?"
                class="col-12 col-md-4 col-lg-5"
                label-for="health-expenditure-risk-dosagem_remedio_controlado"
                v-show="customer.pessoa.pessoa_saude.toma_remedio_controlado"
              >
                <b-form-input
                  class="form-control"
                  v-model="remedio.dosagem"
                  type="text"
                  id="health-expenditure-risk-dosagem_remedio_controlado"
                />
              </b-form-group>

              <b-col md="3" lg="2" class="text-right">
                <b-button
                  pill
                  variant="danger"
                  @click="() => handleDeleteRemedio(index)"
                >
                  <i class="fas fa-trash-alt" />
                </b-button>
              </b-col>
            </b-form-row>

            <b-button-group class="mt-3">
              <b-button @click="() => addRemedio()" variant="primary">
                ADICIONAR REMÉDIO
              </b-button>

              <b-button @click="() => addRemedio()" variant="dark-primary">
                <i class="bx bx-plus" style="font-size: 0.95rem"></i>
              </b-button>
            </b-button-group>
          </b-col>

          <hr style="width: 100%" />

          <b-form-group
            label="Tem alguma previsão de cirurgia futura?"
            :class="`col-12 ${
              customer.pessoa.pessoa_saude.possui_previsao_cirurgia
                ? 'col-md-6 col-lg-4'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_saude.possui_previsao_cirurgia"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            class="col-12"
            label-for="health-expenditure-risk-ate_quando-input"
            v-show="customer.pessoa.pessoa_saude.possui_previsao_cirurgia"
          >
            <p class="mb-0">Quais cirúrgias?</p>

            <b-form-textarea
              id="health-expenditure-risk-ate_quando-input"
              rows="5"
              v-model="customer.pessoa.pessoa_saude.previsao_cirurgia"
            />
          </b-form-group>

          <hr style="width: 100%" />

          <b-form-group
            label="Algum histórico de doença na familia (pais/irmãos)?"
            :class="`col-12 ${
              customer.pessoa.pessoa_saude.possui_historico_doenca_familia
                ? 'col-md-6 col-lg-4'
                : ''
            }`"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_saude.possui_historico_doenca_familia
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            class="col-12"
            label-for="health-expenditure-risk-ate_quando-input"
            v-show="
              customer.pessoa.pessoa_saude.possui_historico_doenca_familia
            "
          >
            <p class="mb-0">Quais doenças?</p>

            <b-form-textarea
              id="health-expenditure-risk-ate_quando-input"
              rows="5"
              v-model="customer.pessoa.pessoa_saude.historico_doenca_familia"
            />
          </b-form-group>

          <hr style="width: 100%" />

          <b-form-group
            label="Tem algum histórico de doenças pré-existentes?"
            :class="`col-12 col-md-6 col-lg-${
              customer.pessoa.pessoa_saude.possui_historico_doenca_preexistente
                ? '4'
                : '12'
            }`"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_saude
                  .possui_historico_doenca_preexistente
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            class="col-12"
            label-for="health-expenditure-risk-doencas_pre_existentes-input"
            v-show="
              customer.pessoa.pessoa_saude.possui_historico_doenca_preexistente
            "
          >
            <p class="mb-0">Se sim, quais doenças pré-existentes?</p>

            <b-form-textarea
              id="health-expenditure-risk-doencas_pre_existentes-input"
              rows="5"
              v-model="
                customer.pessoa.pessoa_saude.historico_doenca_preexistente
              "
            />
          </b-form-group>

          <hr style="width: 100%" />

          <b-form-group
            label="Você tem algum exame fora da normalidade?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_saude.possui_exame_anormal"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            class="col-12"
            label-for="health-expenditure-risk-exame_fora_da_normalidade-input"
            v-show="customer.pessoa.pessoa_saude.possui_exame_anormal"
          >
            <p class="mb-0">Quais?</p>

            <b-form-textarea
              id="health-expenditure-risk-exame_fora_da_normalidade-input"
              rows="5"
              v-model="customer.pessoa.pessoa_saude.exame_anormal"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-heartbeat"></i>
        <span class="ml-2">Sobre a sua segurança em gastos com saúde</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Você tem seguro ou plano de saúde?"
            class="col-12 col-md-6 col-lg-5"
          >
            <b-form-radio-group
              v-model="customer.pessoa.saude.possui_seguro_plano"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Pessoa fisica, juridica ou entidade de classe?"
            class="col-12 col-md-6 col-lg-7"
            label-for="health-expenditure-risk-fisica-ou-juridica-input"
          >
            <b-form-select
              id="health-expenditure-risk-fisica-ou-juridica-input"
              v-model="customer.pessoa.saude.tipo_pessoa"
              :options="tipoPessoaSaudeOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual a operadora?"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-operadora-input"
          >
            <b-form-input
              class="form-control"
              id="health-expenditure-risk-operadora-input"
              type="text"
              v-model="customer.pessoa.saude.operadora"
            />
          </b-form-group>

          <b-form-group
            label="Qual o nome do produto?"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-produto-input"
          >
            <b-form-input
              class="form-control"
              id="health-expenditure-risk-produto-input"
              type="text"
              v-model="customer.pessoa.saude.nome_produto"
            />
          </b-form-group>

          <b-form-group
            label="Qual o valor pago mensalmente?"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-pago-mensalmente-input"
          >
            <money
              class="form-control"
              v-bind="money"
              id="health-expenditure-risk-pago-mensalmente-input"
              type="text"
              v-model="customer.pessoa.saude.valor_mensal"
            />
          </b-form-group>

          <b-form-group
            label="Co-participação"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="customer.pessoa.saude.coparticipacao"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Tipo de cobertura"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-cobertura-input"
          >
            <b-form-select
              id="health-expenditure-risk-cobertura-input"
              v-model="customer.pessoa.saude.tipo_cobertura"
              :options="tipoCoberturaOptions"
            />
          </b-form-group>

          <b-form-group
            label="Faz uso de reembolso? Se sim, qual valor?"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-reembolso-input"
          >
            <money
              class="form-control"
              :value="0"
              v-bind="money"
              id="health-expenditure-risk-reembolso-input"
              type="text"
              v-model="customer.pessoa.saude.valor_reembolso"
            />
          </b-form-group>

          <b-form-group
            label="Está satisfeito?"
            class="col-12 col-md-6 col-xl-3"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_saude.satisfeito"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Já avaliou redução de custos com o mesmo padrão de cobertura?"
            class="col-12 col-md-6"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_saude.avaliou_reducao_custos"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Tem algum hospital ou rede de preferência?"
            class="col-12 col-md-6 col-lg-3"
            label-for="health-expenditure-risk-hospital_preferencia-input"
          >
            <b-form-input
              class="form-control"
              id="health-expenditure-risk-hospital_preferencia-input"
              type="text"
              v-model="customer.pessoa.saude.possui_preferencia"
            />
          </b-form-group>

          <b-form-group
            label="Acomodação"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-acomodacao-input"
          >
            <b-form-select
              id="health-expenditure-risk-acomodacao-input"
              v-model="customer.pessoa.saude.acomodacao"
              :options="acomodacaoOptions"
            />
          </b-form-group>

          <b-form-group
            label="Estado"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-estado-input"
          >
            <b-form-select
              id="health-expenditure-risk-estado-input"
              v-model="customer.pessoa.saude.estado"
              :options="stateOptions"
            />
          </b-form-group>

          <b-form-group
            label="Cidade"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-cidade-input"
          >
            <b-form-input
              class="form-control"
              id="health-expenditure-risk-cidade-input"
              type="text"
              v-model="customer.pessoa.saude.cidade"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      v-if="['C', 'UE'].includes(customer.pessoa.pessoa_estado_civil)"
      class="diagnosis-info-section"
    >
      <b-card-title class="text-primary">
        <i class="fas fa-user-circle"></i>
        <span class="ml-2">Cônjuge</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Nome"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-name-input"
          >
            <b-form-input
              v-model="customer.pessoa.pessoa_conjuge.nome_conjuge"
              id="dependent-health-spouse-name-input"
            />
          </b-form-group>

          <b-form-group
            label="Tem plano de saúde?"
            class="col-12 col-md-6 col-xl-4"
            label-for="plano-de-saude"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_conjuge.saude.possui_seguro_plano"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Já esta incluso no valor do titular?"
            class="col-12 col-md-6 col-xl-4"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Pessoa física jurídica ou entidade de classe?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-fisica-ou-juridica-input"
          >
            <b-form-select
              id="dependent-health-spouse-fisica-ou-juridica-input"
              v-model="customer.pessoa.pessoa_conjuge.saude.tipo_pessoa"
              :options="tipoPessoaSaudeOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Qual a operadora?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-operadora-input"
          >
            <b-form-input
              id="dependent-health-spouse-operadora-input"
              type="text"
              v-model="customer.pessoa.pessoa_conjuge.saude.operadora"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Qual o nome do produto?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-nome-produto-input"
          >
            <b-form-input
              type="text"
              id="dependent-health-spouse-nome-produto-input"
              v-model="customer.pessoa.pessoa_conjuge.saude.nome_produto"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Qual o valor pago mensalmente?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-pago-mensalmente-2-input"
          >
            <money
              class="form-control"
              v-bind="money"
              id="dependent-health-spouse-pago-mensalmente-input"
              type="text"
              v-model="customer.pessoa.pessoa_conjuge.saude.valor_mensal"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Co-participação"
            class="col-12 col-md-6 col-xl-4"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_conjuge.saude.coparticipacao"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Tipo de cobertura"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-tipo-cobertura-input"
          >
            <b-form-select
              id="dependent-health-spouse-tipo-cobertura-input"
              v-model="customer.pessoa.pessoa_conjuge.saude.tipo_cobertura"
              :options="tipoCoberturaOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Faz uso de reembolso? Se sim, qual valor?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-reembolso-input"
          >
            <money
              class="form-control"
              v-bind="money"
              id="dependent-health-spouse-reembolso-input"
              type="text"
              v-model="customer.pessoa.pessoa_conjuge.saude.valor_reembolso"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Tem algum hospital ou rede de preferência?"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-hospital_preferencia-input"
          >
            <b-form-input
              class="form-control"
              id="dependent-health-spouse-hospital_preferencia-input"
              type="text"
              v-model="customer.pessoa.pessoa_conjuge.saude.possui_preferencia"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Acomodação"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-acomodacao-input"
          >
            <b-form-select
              id="dependent-health-spouse-acomodacao-input"
              v-model="customer.pessoa.pessoa_conjuge.saude.acomodacao"
              :options="acomodacaoOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Estado"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-estado-input"
          >
            <b-form-select
              id="dependent-health-spouse-estado-input"
              v-model="customer.pessoa.pessoa_conjuge.saude.estado"
              :options="stateOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="!customer.pessoa.pessoa_conjuge.saude.incluso_valor_titular"
            label="Cidade"
            class="col-12 col-md-6 col-lg-4"
            label-for="dependent-health-spouse-cidade-input"
          >
            <b-form-input
              class="form-control"
              id="dependent-health-spouse-cidade-input"
              type="text"
              v-model="customer.pessoa.pessoa_conjuge.saude.cidade"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      v-for="(dependent, index) in customer.pessoa.dependentes"
      :key="index"
      class="diagnosis-info-section"
    >
      <b-card-title
        class="text-primary d-flex align-items-center justify-content-between"
      >
        <span>
          <i class="fas fa-user-circle"></i>
          <span class="ml-2">Dependente {{ index + 1 }}</span>
        </span>

        <b-button
          pill
          variant="danger"
          @click="() => handleDeleteDependent(index)"
        >
          <i class="fas fa-trash-alt" />
        </b-button>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Nome"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-name-input`"
          >
            <b-form-input
              v-model="dependent.nome_dependente"
              :id="`dependent-health-dependent-${index}-name-input`"
            />
          </b-form-group>

          <b-form-group
            label="Tem plano de saúde?"
            class="col-12 col-md-6 col-xl-4"
          >
            <b-form-radio-group
              v-model="dependent.saude.possui_seguro_plano"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Já esta incluso no valor do titular?"
            class="col-12 col-md-6 col-xl-4"
            v-show="!dependent.saude.incluso_valor_conjuge"
          >
            <b-form-radio-group
              v-model="dependent.saude.incluso_valor_titular"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Já esta incluso no valor do cônjuge?"
            class="col-12 col-md-6 col-xl-4"
            v-show="
              !dependent.saude.incluso_valor_titular &&
              customer.pessoa.pessoa_conjuge.saude.possui_seguro_plano
            "
          >
            <b-form-radio-group
              v-model="dependent.saude.incluso_valor_conjuge"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Pessoa física jurídica ou entidade de classe?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-fisica-ou-juridica-input`"
          >
            <b-form-select
              :id="`dependent-health-dependent-${index}-fisica-ou-juridica-input`"
              v-model="dependent.saude.tipo_pessoa"
              :options="tipoPessoaSaudeOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Qual a operadora?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-operadora-input`"
          >
            <b-form-input
              :id="`dependent-health-dependent-${index}-operadora-input`"
              type="text"
              v-model="dependent.saude.operadora"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Qual o nome do produto?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-nome-produto-input`"
          >
            <b-form-input
              type="text"
              :id="`dependent-health-dependent-${index}-nome-produto-input`"
              v-model="dependent.saude.nome_produto"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Qual o valor pago mensalmente?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-pago-mensalmente-3-input`"
          >
            <money
              class="form-control"
              v-bind="money"
              :id="`dependent-health-dependent-${index}-pago-mensalmente-input`"
              type="text"
              v-model="dependent.saude.valor_mensal"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Co-participação"
            class="col-12 col-md-6 col-xl-4"
          >
            <b-form-radio-group
              v-model="dependent.saude.coparticipacao"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Tipo de cobertura"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-tipo-cobertura-input`"
          >
            <b-form-select
              :id="`dependent-health-dependent-${index}-tipo-cobertura-input`"
              v-model="dependent.saude.tipo_cobertura"
              :options="tipoCoberturaOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Faz uso de reembolso? Se sim, qual valor?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-reembolso-input`"
          >
            <money
              class="form-control"
              v-bind="money"
              :id="`dependent-health-dependent-${index}-reembolso-input`"
              type="text"
              v-model="dependent.saude.valor_reembolso"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Tem algum hospital ou rede de preferência?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-hospital_preferencia-input`"
          >
            <b-form-input
              class="form-control"
              :id="`dependent-health-dependent-${index}-hospital_preferencia-input`"
              type="text"
              v-model="dependent.saude.possui_preferencia"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Acomodação"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-acomodacao-input`"
          >
            <b-form-select
              :id="`dependent-health-dependent-${index}-acomodacao-input`"
              v-model="dependent.saude.acomodacao"
              :options="acomodacaoOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Estado"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-estado-input`"
          >
            <b-form-select
              :id="`dependent-health-dependent-${index}-estado-input`"
              v-model="dependent.saude.estado"
              :options="stateOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !dependent.saude.incluso_valor_titular &&
              !dependent.saude.incluso_valor_conjuge
            "
            label="Cidade"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`dependent-health-dependent-${index}-cidade-input`"
          >
            <b-form-input
              class="form-control"
              :id="`dependent-health-dependent-${index}-cidade-input`"
              type="text"
              v-model="dependent.saude.cidade"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-button-group class="mb-3">
      <b-button @click="() => addDependent()" variant="primary">
        ADICIONAR DEPENDENDE
      </b-button>

      <b-button @click="() => addDependent()" variant="dark-primary">
        <i class="bx bx-plus" style="font-size: 0.95rem"></i>
      </b-button>
    </b-button-group>

    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-clipboard"></i>
        <span class="ml-2" style="position: relative">
          Contato para levantamento de plano ou seguro pessoa jurídica
          <i
            v-b-tooltip.hover
            title="Preencher em caso de necessidade de envolvimento da área de Recursos Humanos"
            class="bx bx-help-circle"
            style="font-size: 1.2rem; position: absolute; right: -1.5rem"
          ></i>
        </span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Razão social"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-razao_social-input"
          >
            <b-form-input
              id="health-expenditure-risk-razao_social-input"
              v-model="customer.pessoa.pessoa_saude.contato_razao_social"
            />
          </b-form-group>

          <b-form-group
            label="CNPJ"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-cnpj-input"
          >
            <b-form-input
              id="health-expenditure-risk-cnpj-input"
              type="text"
              v-mask="'##.###.###/####-##'"
              v-model="customer.pessoa.pessoa_saude.contato_cnpj"
            />
          </b-form-group>

          <b-form-group
            label="Nome do representante"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-nome_representante-input"
          >
            <b-form-input
              id="health-expenditure-risk-nome_representante-input"
              v-model="customer.pessoa.pessoa_saude.contato_representante"
            />
          </b-form-group>

          <b-form-group
            id="telephone-input-group"
            label="Telefone"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-telephone-input"
          >
            <VuePhoneNumberInput
              id="health-expenditure-risk-telephone-input"
              :translations="numberInputTranslations"
              v-model="customer.pessoa.pessoa_saude.contato_telefone"
            />
          </b-form-group>

          <b-form-group
            label="E-mail"
            class="col-12 col-md-6 col-lg-4"
            label-for="health-expenditure-risk-email-input"
          >
            <b-form-input
              type="email"
              id="health-expenditure-risk-email-input"
              v-model="customer.pessoa.pessoa_saude.contato_email"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="health-expenditure-risk-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_saude.observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';

import {
  tipoPessoaSaudeOptions,
  fumanteOptions,
  tipoCoberturaOptions,
  stateOptions,
  acomodacaoOptions
} from '@/constants/options';

import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  components: { Money, VuePhoneNumberInput },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      height: {
        decimal: ',',
        thousands: '.',
        suffix: ' m',
        precision: 2,
        masked: false
      },
      weight: {
        decimal: ',',
        thousands: '.',
        suffix: ' kg',
        precision: 0,
        masked: false
      },
      tipoPessoaSaudeOptions,
      fumanteOptions,
      tipoCoberturaOptions,
      stateOptions,
      acomodacaoOptions
    };
  },
  methods: {
    addDependent() {
      this.customer.pessoa.dependentes.push(
        cloneObject(defaultData.dependente)
      );
    },
    handleDeleteDependent(index) {
      this.customer.pessoa.dependentes.splice(index, 1);
    },
    addRemedio() {
      this.customer.pessoa.pessoa_remedios.push(
        cloneObject(defaultData.pessoa_remedio)
      );
    },
    handleDeleteRemedio(index) {
      this.customer.pessoa.pessoa_remedios.splice(index, 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
