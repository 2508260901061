<template>
  <main id="diagnosis-complete">
    <PageHeader :items="breadcrumb" />

    <div
      v-if="loading"
      class="d-flex justify-content-center mb-3"
      style="margin: 2rem 0"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>

    <TabsGenerateStudyComplete
      :customer="customer"
      v-if="customer && !loading"
    />
  </main>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import TabsGenerateStudyComplete from '@/components/DiagnosisComplete/DiagnosisComplete/TabsGenerateStudyComplete';

import moment from 'moment';
import { requestGetCustomerId } from '@/services/requests/customer';
import defaultData from '@/constants/defaultData';
import { cloneObject } from '@/helpers';

export default {
  name: 'GenerateDiagnosisComplete',
  components: {
    TabsGenerateStudyComplete,
    PageHeader
  },
  data() {
    return {
      breadcrumb: [
        {
          text: 'Dashboards',
          href: '/'
        },
        {
          text: 'Gerar estudo completo',
          active: true
        }
      ],
      customer: null,
      loading: false
    };
  },
  methods: {
    treatCustomerData(customer) {
      if (!customer.pessoa.endereco) {
        customer.pessoa.endereco = cloneObject(defaultData.endereco);
      }

      if (!customer.pessoa.perfil_familia) {
        customer.pessoa.perfil_familia = cloneObject(
          defaultData.perfil_familia
        );
      }

      if (!customer.pessoa.pessoa_renda) {
        customer.pessoa.pessoa_renda = cloneObject(defaultData.pessoa_renda);
      }

      if (!customer.pessoa.pessoa_renda.pessoa_renda_mensais) {
        customer.pessoa.pessoa_renda.pessoa_renda_mensais = [];
      }

      if (!customer.pessoa.pessoa_renda.pessoa_renda_anuais) {
        customer.pessoa.pessoa_renda.pessoa_renda_anuais = [];
      }

      if (!customer.pessoa.dependentes) {
        customer.pessoa.dependentes = [];
      }

      if (!customer.pessoa.beneficiarios) {
        customer.pessoa.beneficiarios = [];
      }

      if (!customer.pessoa.estudo) {
        customer.pessoa.estudo = cloneObject(defaultData.estudo);
      }

      if (!customer.pessoa.pessoa_conjuge) {
        customer.pessoa.pessoa_conjuge = cloneObject(
          defaultData.pessoa_conjuge
        );
      }

      if (!customer.pessoa.pessoa_conjuge.estudo) {
        customer.pessoa.pessoa_conjuge.estudo = cloneObject(defaultData.estudo);
      }

      if (!customer.pessoa.pessoa_conjuge.saude) {
        customer.pessoa.pessoa_conjuge.saude = cloneObject(defaultData.saude);
      }

      if (!customer.pessoa.patrimonio_investimento) {
        customer.pessoa.patrimonio_investimento = cloneObject(
          defaultData.patrimonio_investimento
        );
      }

      if (!customer.pessoa.patrimonio_investimento.patrimonio_itens) {
        customer.pessoa.patrimonio_investimento.patrimonio_itens = [];
      }

      if (!customer.pessoa.patrimonio_investimento.investimento_itens) {
        customer.pessoa.patrimonio_investimento.investimento_itens = [];
      }

      if (!customer.pessoa.pessoa_despesa) {
        customer.pessoa.pessoa_despesa = cloneObject(
          defaultData.pessoa_despesa
        );
      }

      if (!customer.pessoa.pessoa_despesa.despesa_itens) {
        customer.pessoa.pessoa_despesa.despesa_itens = [];
      }

      if (!customer.pessoa.sonhos) {
        customer.pessoa.sonhos = [];
      }

      if (!customer.pessoa.pessoa_plano) {
        customer.pessoa.pessoa_plano = cloneObject(defaultData.pessoa_plano);
      }

      if (!customer.pessoa.seguro_itens) {
        customer.pessoa.seguro_itens = [];
      }

      if (!customer.pessoa.pessoa_previdencia_privada) {
        customer.pessoa.pessoa_previdencia_privada = cloneObject(
          defaultData.pessoa_previdencia_privada
        );
      }

      if (!customer.pessoa.pessoa_previdencia_privada.previdencia_fundos) {
        customer.pessoa.pessoa_previdencia_privada.previdencia_fundos = [];
      }

      if (!customer.pessoa.pessoa_saude) {
        customer.pessoa.pessoa_saude = cloneObject(defaultData.pessoa_saude);
      }

      if (!customer.pessoa.saude) {
        customer.pessoa.saude = cloneObject(defaultData.saude);
      }

      if (!customer.pessoa.prioridades) {
        customer.pessoa.prioridades = [];
      }

      if (!customer.pessoa.indicacoes) {
        customer.pessoa.indicacoes = [];
      }

      if (!customer.pessoa.pessoa_remedios) {
        customer.pessoa.pessoa_remedios = [];
      }

      if (!customer.pessoa.dependentes_plano) {
        customer.pessoa.dependentes_plano = [];
      }

      if (!customer.pessoa.pessoa_pagamento) {
        customer.pessoa.pessoa_pagamento = cloneObject(
          defaultData.pessoa_pagamento
        );
      }

      if (!customer.pessoa.pessoa_pagamento.endereco) {
        customer.pessoa.pessoa_pagamento.endereco = cloneObject(
          defaultData.endereco
        );
      }

      if (customer.pessoa.dependentes) {
        customer.pessoa.dependentes.forEach((dependent, index) => {
          if (!dependent.estudo) {
            customer.pessoa.dependentes[index].estudo = cloneObject(
              defaultData.estudo
            );
          }

          if (!dependent.saude) {
            customer.pessoa.dependentes[index].saude = cloneObject(
              defaultData.saude
            );
          }
        });
      }

      if (customer.pessoa.pessoa_data_nascimento) {
        customer.pessoa.pessoa_data_nascimento = moment(
          customer.pessoa.pessoa_data_nascimento
        ).format('YYYY-MM-DD');
      }

      return customer;
    },

    loadCustomer() {
      this.loading = true;
      requestGetCustomerId(this.customerId)
        .then((res) => {
          this.loading = false;
          this.customer = this.treatCustomerData(res.data.result.data);
        })
        .catch((error) => {
          this.loading = false;
          this.$store.state.modal.isOpenModalErrorRequest = true;
          this.$store.state.modal.errorMessageModal =
            error.response.data.message;
        });
    }
  },
  computed: {
    customerId() {
      return this.$route.params.customerId;
    }
  },
  mounted() {
    this.loadCustomer();
    this.$store.dispatch('diagnosis/loadInsurers');
  }
};
</script>
