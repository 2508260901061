<template>
  <b-card>
    <b-card class="diagnosis-info-section">
      <b-card-title class="text-primary">
        <i class="fas fa-donate"></i>
        <span class="ml-2">Vamos falar sobre sua liberdade financeira.</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row class="align-items-end">
          <b-form-group
            label="Qual a renda desejada para a sua liberdade financeira, trabalhar menos ou para a sua aposentadoria?"
            class="col-12 col-lg-6"
            label-for="plans-and-dreams-renda-desejada-input"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="
                customer.pessoa.pessoa_plano
                  .plano_renda_desejada_liberdade_financeira
              "
              id="plans-and-dreams-renda-desejada-input"
            />
          </b-form-group>

          <b-form-group
            label="Com quantos anos gostaria de atingir sua liberdade financeira / trabalhar menos / aposentar?"
            class="col-12 col-lg-6"
            label-for="plans-and-dreams-idade-desejada-input"
          >
            <b-form-input
              class="form-control"
              v-model="
                customer.pessoa.pessoa_plano
                  .plano_quantos_anos_liberdade_financeira
              "
              type="number"
              id="plans-and-dreams-renda-desejada-input"
            />
          </b-form-group>

          <b-form-group
            label="Até quantos anos gostaria de garantir esta renda?"
            class="col-12 col-lg-6"
            label-for="plans-and-dreams-ate_quando-input"
          >
            <b-form-input
              class="form-control"
              v-model="
                customer.pessoa.pessoa_plano
                  .plano_ate_quantos_anos_liberdade_financeira
              "
              type="number"
              id="plans-and-dreams-ate_quando-input"
            />
          </b-form-group>
        </b-form-row>

        <b-form-row class="align-items-end">
          <b-form-group
            label="É contribuinte de previdencia oficial ?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_despesa
                  .contribuinte_previdencia_aposentado
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="É atualmente aposentado ou com mais de 65 anos?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="customer.pessoa.pessoa_despesa.aposentado_maior_65_anos"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-if="customer.pessoa.pessoa_despesa.aposentado_maior_65_anos"
            label="Qual renda mensal da aposentadoria?"
            class="col-12 col-md-6 col-lg-4"
          >
            <money
              class="form-control"
              v-bind="money"
              v-model="customer.pessoa.pessoa_despesa.teste"
            />
          </b-form-group>

          <b-form-group
            label="Qual sua expectativa de renda na aposentadoria pelo INSS?"
            class="col-12 col-md-6 col-lg-5"
            label-for="expense-expectativa_renda_aposentadoria_inss-input"
          >
            <money
              id="expense-expectativa_renda_aposentadoria_inss-input"
              class="form-control"
              v-bind="money"
              v-model="
                customer.pessoa.pessoa_despesa.expectativa_renda_aposentadoria
              "
            />
          </b-form-group>
        </b-form-row>

        <b-form-row class="align-items-end">
          <b-form-group
            class="col-12 col-lg-6"
            label="Possui algum investimento em previdência privada?"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_previdencia_privada.possui_investimento
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            v-show="
              !customer.pessoa.pessoa_previdencia_privada.possui_investimento
            "
            label="Já pensou em iniciar?"
            class="col-12 col-lg-6"
          >
            <b-form-radio-group
              v-model="
                customer.pessoa.pessoa_previdencia_privada.pensou_iniciar
              "
              :options="yesOrNotOptions"
            />
          </b-form-group>
        </b-form-row>
      </div>
    </b-card>

    <b-card
      class="diagnosis-info-section"
      v-show="customer.pessoa.pessoa_previdencia_privada.possui_investimento"
    >
      <b-card-title class="text-primary">
        <i class="fas fa-donate"></i>
        <span class="ml-2">Como está alocada sua previdência privada.</span>
      </b-card-title>

      <div class="diagnosis-info-content">
        <b-form-row
          class="align-items-end"
          v-for="(pensionFund, index) in customer.pessoa
            .pessoa_previdencia_privada.previdencia_fundos"
          :key="index"
        >
          <b-form-group
            label="Fundo"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-name-input`"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-input
              :id="`financial-freedom-pension-fund-${index}-name-input`"
              v-model="pensionFund.nome"
              :aria-describedby="ariaDescribedby"
              type="text"
            />
          </b-form-group>

          <b-form-group
            label="CNPJ"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-cnpj-input`"
            v-slot="{ ariaDescribedby }"
          >
            <b-form-input
              :id="`financial-freedom-pension-fund-${index}-cnpj-input`"
              v-model="pensionFund.cnpj"
              :aria-describedby="ariaDescribedby"
              type="text"
              v-mask="'##.###.###/####-##'"
            />
          </b-form-group>

          <b-form-group
            label="Tipo do plano"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-plan-type-input`"
          >
            <b-form-select
              :id="`financial-freedom-pension-fund-${index}-plan-type-input`"
              v-model="pensionFund.tipo_plano"
              :options="tipoPlanoOptions"
            />
          </b-form-group>

          <b-form-group
            label="Tributação"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-tributacao-input`"
          >
            <b-form-select
              :id="`financial-freedom-pension-fund-${index}-tributacao-input`"
              v-model="pensionFund.tributacao"
              :options="tributacaoOptions"
            />
          </b-form-group>

          <b-form-group
            label="Saldo atual"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-valor-atual-input`"
            v-slot="{ ariaDescribedby }"
          >
            <money
              class="form-control"
              v-bind="money"
              :id="`financial-freedom-pension-fund-${index}-valor-atual-input`"
              v-model="pensionFund.valor_atual"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>

          <b-form-group
            label="Custo de inventário"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="pensionFund.custo_inventario"
              :options="yesOrNotOptions"
            />
            <i
              v-b-tooltip.hover
              title="O montante deste fundo de Previdência pode ser utilizado para o pagamento de custas de inventário?"
              class="bx bx-help-circle"
              style="
                font-size: 1.2rem;
                position: absolute;
                left: 140px;
                top: -27px;
              "
            />
          </b-form-group>

          <b-form-group
            label="Faz aportes regularmente?"
            class="col-12 col-md-6 col-lg-4"
          >
            <b-form-radio-group
              v-model="pensionFund.faz_aportes_regularmente"
              :options="yesOrNotOptions"
            />
          </b-form-group>

          <b-form-group
            label="Qual valor?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`financial-freedom-pension-fund-${index}-valor-aportes-input`"
            v-slot="{ ariaDescribedby }"
            v-show="pensionFund.faz_aportes_regularmente"
          >
            <money
              class="form-control"
              v-bind="money"
              :id="`financial-freedom-pension-fund-${index}-valor-aportes-input`"
              v-model="pensionFund.valor_aportes"
              :aria-describedby="ariaDescribedby"
            />
          </b-form-group>

          <b-form-group
            label="Com qual frequencia faz aportes?"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-aportes-input`"
            v-show="pensionFund.faz_aportes_regularmente"
          >
            <b-form-select
              v-model="pensionFund.frequencia_aportes"
              :options="aportesOptions"
              :id="`equity-investments-patrimony-${index}-aportes-input`"
            />
          </b-form-group>

          <b-form-group
            v-if="
              pensionFund.faz_aportes_regularmente &&
              pensionFund.frequencia_aportes === 5
            "
            label="Outra frequencia aportes"
            class="col-12 col-md-6 col-lg-4"
            :label-for="`equity-investments-patrimony-${index}-aportes-input`"
          >
            <b-form-input
              placeholder="Outros aportes"
              v-model="pensionFund.frequencia_aportes_customizada"
              :id="`equity-investments-patrimony-${index}-outros-aportes-input`"
            />
          </b-form-group>

          <b-col cols="12" class="text-right mb-lg-3">
            <b-button
              pill
              variant="danger"
              @click="() => handleDeletePensionFund(index)"
            >
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-col>

          <hr class="col-12 mt-2" />
        </b-form-row>
      </div>

      <b-button-group>
        <b-button @click="() => addPensionFund()" variant="primary">
          ADICIONAR FUNDO
        </b-button>
        <b-button @click="() => addPensionFund()" variant="dark-primary">
          <i class="bx bx-plus" style="font-size: 0.95rem"></i>
        </b-button>
      </b-button-group>
    </b-card>

    <b-card class="diagnosis-info-section">
      <p>Alguma observação?</p>

      <div class="diagnosis-info-content">
        <b-form-group label-for="comment-input">
          <b-form-textarea
            id="financial-freedom-observation-textarea"
            rows="5"
            v-model="customer.pessoa.pessoa_previdencia_privada.observacao"
            placeholder="Observação..."
          />
        </b-form-group>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { Money } from 'v-money';

import defaultData from '@/constants/defaultData';

import {
  tipoPlanoOptions,
  tributacaoOptions,
  tipoRendaOptions,
  aportesOptions
} from '@/constants/options';

import { cloneObject } from '@/helpers';

export default {
  name: 'CustomerProfile',
  mixins: [validationMixin],
  components: { Money },
  props: {
    customer: {
      type: Object,
      required: true
    },
    errors: Object
  },
  data() {
    return {
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      },
      tipoPlanoOptions,
      tributacaoOptions,
      tipoRendaOptions,
      aportesOptions
    };
  },
  methods: {
    addPensionFund() {
      this.customer.pessoa.pessoa_previdencia_privada.previdencia_fundos.push(
        cloneObject(defaultData.previdencia_fundo)
      );
    },
    handleDeletePensionFund(index) {
      this.customer.pessoa.pessoa_previdencia_privada.previdencia_fundos.splice(
        index,
        1
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.diagnosis-info-section {
  background-color: var(--background-light-grey);
  border-radius: 0;
  box-shadow: none;
}

.diagnosis-info-section .card-title {
  font-size: 1.125rem;
}

.diagnosis-info-section .diagnosis-info-content {
  max-width: 1200px;
}
</style>
