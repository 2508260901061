<template>
  <div style="width: 100%; position: relative">
    <b-row class="mb-3">
      <b-col md="6">
        <b-card-title>
          Gerar estudo para:
          <router-link :to="{ name: 'home' }">
            {{ customer ? customer.pessoa.pessoa_nome : '' }}
          </router-link>
        </b-card-title>
        <div>Preencha os campos ou importe o Imposto de Renda do cliente.</div>
      </b-col>

      <b-col md="6">
        <b-button
          variant="primary"
          class="d-flex align-items-center ml-auto"
          @click="duplicateStudy()"
        >
          <i class="bx bx-duplicate mr-2" style="font-size: 1rem" />
          Duplicar estudo
        </b-button>
      </b-col>
    </b-row>
    <form class="col-12" @submit.prevent="handleSubmitSaveDiagnosis()">
      <b-card no-body style="margin: 0 auto">
        <b-tabs
          :active-nav-item-class="
            errors.length > 0 ? 'bg-danger text-white' : ''
          "
          pills
          responsive
          v-model="currentTab"
          ref="tabs"
        >
          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorCustomerProfile"
                    type="grow"
                    :variant="
                      hasErrorCustomerProfile && currentTab !== 0
                        ? 'danger'
                        : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorCustomerProfile &&
                      currentTab !== 0 &&
                      'color: var(--red)'
                    "
                    >Perfil do Cliente</span
                  >
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedCustomerProfile"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <CustomerProfile :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorIncome"
                    type="grow"
                    :variant="
                      hasErrorIncome && currentTab !== 0 ? 'danger' : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorIncome && currentTab !== 0 && 'color: var(--red)'
                    "
                  >
                    Renda</span
                  >
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedIncome"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <Income :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorEquityAndInvestments"
                    type="grow"
                    :variant="
                      hasErrorEquityAndInvestments && currentTab !== 0
                        ? 'danger'
                        : 'light'
                    "
                    small
                  />

                  <span
                    :style="
                      hasErrorEquityAndInvestments &&
                      currentTab !== 0 &&
                      'color: var(--red)'
                    "
                  >
                    Patrimônio e Investimentos
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedEquityAndInvestments"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <EquityAndInvestments :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorExpense"
                    type="grow"
                    :variant="
                      hasErrorExpense && currentTab !== 4 ? 'danger' : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorExpense && currentTab !== 4 && 'color: var(--red)'
                    "
                  >
                    Despesas
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedExpense"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <Expense :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorPlansAndDreams"
                    type="grow"
                    :variant="
                      hasErrorPlansAndDreams && currentTab !== 0
                        ? 'danger'
                        : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorPlansAndDreams &&
                      currentTab !== 0 &&
                      'color: var(--red)'
                    "
                  >
                    Planos e Sonhos
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedPlansAndDreams"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <PlansAndDreams :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorFinancialFreedom"
                    type="grow"
                    :variant="
                      hasErrorFinancialFreedom && currentTab !== 0
                        ? 'danger'
                        : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorFinancialFreedom &&
                      currentTab !== 0 &&
                      'color: var(--red)'
                    "
                    >Liberdade financeira</span
                  >
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedFinancialFreedom"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <FinancialFreedom :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorHealthExpenditureRisk"
                    type="grow"
                    :variant="
                      hasErrorHealthExpenditureRisk && currentTab !== 7
                        ? 'danger'
                        : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorHealthExpenditureRisk &&
                      currentTab !== 7 &&
                      'color: var(--red)'
                    "
                  >
                    Riscos de Gastos com Saúde
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedHealthExpenditureRisk"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <HealthExpenditureRisk :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>
                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorPlanB"
                    type="grow"
                    :variant="
                      hasErrorPlanB && currentTab !== 0 ? 'danger' : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorPlanB && currentTab !== 0 && 'color: var(--red)'
                    "
                  >
                    Riscos com perda de renda
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedPlanB"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <PlanB
              :customer="customer"
              :errors="errors"
              :updateCustomer="(onSuccess) => updateCustomer(onSuccess)"
            />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button variant="info" type="submit">
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>

                <b-button variant="primary" type="submit" @click="currentTab++">
                  Continuar
                </b-button>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab no-body title-item-class="tab-product">
            <template #title>
              <div style="width: 100%; position: relative">
                <div class="div-icon-title">
                  <b-spinner
                    v-if="hasErrorPriorities"
                    type="grow"
                    :variant="
                      hasErrorPriorities && currentTab !== 8
                        ? 'danger'
                        : 'light'
                    "
                    small
                  ></b-spinner>
                  <span
                    :style="
                      hasErrorPriorities &&
                      currentTab !== 8 &&
                      'color: var(--red)'
                    "
                  >
                    Prioridades
                  </span>
                </div>

                <div class="check-icon-container">
                  <i
                    v-if="completedPriorities"
                    class="bx bxs-check-circle check-icon"
                  />
                </div>
              </div>
            </template>
            <Priorities :customer="customer" :errors="errors" />
            <b-row class="mb-3 px-3">
              <b-col sm="6">
                <b-button
                  variant="info"
                  @click="() => handleSubmitSaveDiagnosis(true)"
                >
                  SALVAR E CONTINUAR DEPOIS
                </b-button>
              </b-col>

              <b-col sm="6" class="mb-3 mb-sm-0 text-sm-right">
                <b-button
                  style="margin-right: 1rem"
                  variant="primary"
                  type="submit"
                  @click="currentTab--"
                >
                  Voltar
                </b-button>

                <b-button
                  variant="primary"
                  type="submit"
                  @click="generateDiagnosis()"
                >
                  Gerar Estudo
                </b-button>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <div class="d-flex justify-content-center mb-3">
          <b-spinner
            v-if="loading"
            variant="primary"
            type="grow"
            label="Spinning"
          ></b-spinner>
          <p id="savecont"></p>
        </div>
      </b-card>

      <div class="text-right my-3">
        <b-button variant="primary" @click="backToTheTop()">
          Voltar ao topo
        </b-button>
      </div>
    </form>
  </div>
</template>

<script>
import CustomerProfile from './Tabs/CustomerProfile';
import Income from './Tabs/Income';
import EquityAndInvestments from './Tabs/EquityAndInvestments';
import Expense from './Tabs/Expense';
import PlansAndDreams from './Tabs/PlansAndDreams';
import FinancialFreedom from './Tabs/FinancialFreedom';
import HealthExpenditureRisk from './Tabs/HealthExpenditureRisk';
import PlanB from './Tabs/PlanB';
import Priorities from './Tabs/Priorities';

import createToastMixin from '@/mixins/create-toast-mixin';
import api from '@/utils/api';
import { createDiagnosis } from '@/services/requests/diagnosis';
import { duplicateCustomer } from '@/services/requests/customer';
import { cloneObject } from '@/helpers';

export default {
  mixins: [createToastMixin],
  props: {
    customer: Object
  },
  components: {
    CustomerProfile,
    Income,
    EquityAndInvestments,
    Expense,
    PlansAndDreams,
    FinancialFreedom,
    HealthExpenditureRisk,
    PlanB,
    Priorities
  },
  data() {
    return {
      currentTab: 0,
      loading: false,
      saving: false,
      errors: {},
      continuedAfter: false
    };
  },
  watch: {
    currentTab() {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      this.handleSubmitSaveDiagnosis();
    }
  },
  methods: {
    updateCustomer(onSuccess) {
      if (this.saving) return;

      this.saving = true;
      this.loading = true;

      const data = cloneObject(this.customer.pessoa);

      let url = `/motor-interno/cliente/${this.customer.cliente_id}`;

      this.errors = {};

      return api
        .put(url, data)
        .then((res) => {
          this.saving = false;
          this.loading = false;

          onSuccess(res.data.result.data);
        })
        .catch((e) => {
          this.saving = false;
          this.loading = false;

          this.createToast(
            'Houve um erro ao salvar os dados.',
            'Atenção !',
            'danger'
          );

          if (
            e.response &&
            e.response.status === 400 &&
            e.response.data.message
          ) {
            this.errors = e.response.data.message;
          }
        });
    },
    handleSubmitSaveDiagnosis(continueDiagnosis = false) {
      if (this.saving) return;

      this.saving = true;
      this.loading = true;

      const data = cloneObject(this.customer.pessoa);

      let url = `/motor-interno/cliente/${this.customer.cliente_id}`;

      if (continueDiagnosis && !this.continuedAfter) {
        url = `/motor-interno/cliente/${this.customer.cliente_id}/continuar-diagnostico`;
      }

      this.errors = {};

      return api
        .put(url, data)
        .then(() => {
          this.saving = false;
          this.loading = false;

          if (continueDiagnosis) {
            this.continuedAfter = true;
          }
        })
        .catch((e) => {
          this.saving = false;
          this.loading = false;

          this.createToast(
            'Houve um erro ao salvar os dados.',
            'Atenção !',
            'danger'
          );

          if (
            e.response &&
            e.response.status === 400 &&
            e.response.data.message
          ) {
            this.errors = e.response.data.message;
          }
        });
    },
    async generateDiagnosis() {
      await this.handleSubmitSaveDiagnosis();
      const data = {
        pessoa_id: this.customer.pessoa_id
      };

      if (this.$route.params.continueDiagnosisId) {
        data.continuar_diagnostico_id = this.$route.params.continueDiagnosisId;
      }
      createDiagnosis(data)
        .then((res) => {
          const diagnosisId = res.data.result.data.diagnostico_id;

          this.$router.push({
            name: 'ConsultantDirection',
            params: { diagnosisId }
          });
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    },
    backToTheTop() {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const distaceFromTop =
        this.$refs.tabs.$el.getBoundingClientRect().top + scrollTop;

      const navbarHeight = 70;
      const gap = navbarHeight + 5;

      const targetTopScrool = distaceFromTop - gap;

      window.scrollTo({ behavior: 'smooth', top: targetTopScrool });
    },
    duplicateStudy() {
      duplicateCustomer(this.customer.cliente_id)
        .then((res) => {
          const customerId = res.data.result.data.cliente_id;

          const path = this.$router.resolve({
            name: 'GenerateDiagnosisComplete',
            params: { customerId }
          });
          window.open(path.href, '_blank');
        })
        .catch(() => {
          this.$store.state.modal.isOpenModalErrorRequest = true;
        });
    }
  },
  computed: {
    hasErrorCustomerProfile() {
      const fields = [
        'pessoa_nome',
        'pessoa_data_nascimento',
        'pessoa_sexo',
        'pessoa_cpf',
        'pessoa_email',
        'pessoa_telefone',
        'pessoa_estado_civil',
        'endereco.endereco_cep',
        'endereco.endereco_estado',
        'pessoa_conjuge.data_nasc_conjuge'
      ];

      return Object.keys(this.errors).some((key) => fields.includes(key));
    },
    hasErrorIncome() {
      const fields = [
        'pessoa_renda.pessoa_renda_salario_bruto',
        'pessoa_renda.pessoa_renda_salario_liquido'
      ];

      return Object.keys(this.errors).some((key) => fields.includes(key));
    },
    hasErrorEquityAndInvestments() {
      const fields = [];

      return Object.keys(this.errors).some((key) => fields.includes(key));
    },
    hasErrorExpense() {
      const fields = [
        'pessoa_plano.plano_quanto_tempo_protecao',
        'pessoa_despesa.tipo_declaracao_ir',
        'pessoa_despesa.dependentes',
        'pessoa_despesa.alimentando',
        /dependentes_plano.\d+.\w+/
      ];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    hasErrorPlansAndDreams() {
      const fields = [/sonhos.\d+.ano/];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    hasErrorFinancialFreedom() {
      const fields = [];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    hasErrorHealthExpenditureRisk() {
      const fields = ['pessoa_saude.ano_parou_fumar'];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    hasErrorPlanB() {
      const fields = [];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    hasErrorPriorities() {
      const fields = [
        /prioridades.\d+.tipo/,
        /prioridades.\d+.nome_personalizado/,
        /prioridades.\d+.nota/,
        /indicacoes.\d+.email/
      ];

      return Object.keys(this.errors).some((key) =>
        typeof key === 'string'
          ? fields.includes(key)
          : fields.some((field) => field.test(key))
      );
    },
    completedCustomerProfile() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.pessoa_nome) return false;
      if (!pessoa.pessoa_data_nascimento) return false;
      if (!pessoa.pessoa_sexo) return false;
      if (!pessoa.pessoa_grau_instrucao) return false;
      if (!pessoa.pessoa_situacao_trabalho) return false;
      if (!pessoa.pessoa_profissao) return false;
      if (!pessoa.pessoa_cpf) return false;
      if (!pessoa.tipo_documento_identificacao) return false;
      if (!pessoa.documento_identificacao) return false;
      if (!pessoa.pessoa_email) return false;
      if (!pessoa.pessoa_telefone) return false;
      if (!pessoa.pessoa_celular) return false;
      if (!pessoa.pessoa_preferencia_contato) return false;
      if (!pessoa.pessoa_estado_civil) return false;
      if (!pessoa.pessoa_regime_comunhao) return false;
      if (pessoa.estudo.esta_estudando) {
        if (!pessoa.estudo.tempo_restante) return false;
        if (!pessoa.estudo.valor_mensalidade) return false;
      }
      if (pessoa.estudo.intercambio) {
        if (!pessoa.estudo.intercambio_tempo) return false;
        if (!pessoa.estudo.intercambio_valor) return false;
      }
      if (pessoa.estudo.possui_seguro_educacional) {
        if (!pessoa.estudo.tempo_seguro_educacional) return false;
      }
      if (!pessoa.endereco.endereco_cep) return false;
      if (!pessoa.endereco.endereco_estado) return false;
      if (!pessoa.endereco.endereco_cidade) return false;
      if (!pessoa.pessoa_conjuge.nome_conjuge) return false;
      if (!pessoa.pessoa_conjuge.data_nasc_conjuge) return false;
      if (!pessoa.pessoa_avaliacao_necessidade) return false;
      if (!pessoa.pessoa_conjuge.grau_instrucao_conjuge) return false;
      if (!pessoa.pessoa_conjuge.situacao_trabalho_conjuge) return false;
      if (!pessoa.pessoa_conjuge.profissao_conjuge) return false;
      if (pessoa.pessoa_conjuge.estudo.esta_estudando) {
        if (!pessoa.pessoa_conjuge.estudo.tempo_restante) return false;
        if (!pessoa.pessoa_conjuge.estudo.valor_mensalidade) return false;
      }
      if (pessoa.pessoa_conjuge.estudo.intercambio) {
        if (!pessoa.pessoa_conjuge.estudo.intercambio_tempo) return false;
        if (!pessoa.pessoa_conjuge.estudo.intercambio_valor) return false;
      }
      if (pessoa.pessoa_conjuge.estudo.possui_seguro_educacional) {
        if (!pessoa.pessoa_conjuge.estudo.tempo_seguro_educacional)
          return false;
      }
      if (pessoa.perfil_familia.perfil_familia_possui_dependentes) {
        if (
          pessoa.dependentes.some((dependent) => {
            if (!dependent.nome_dependente) return true;
            if (!dependent.data_nasc_dependente) return true;
            if (!dependent.grau_parentesco_dependente) return true;
            if (dependent.estudo.esta_estudando) {
              if (!dependent.estudo.tempo_restante) return true;
              if (!dependent.estudo.valor_mensalidade) return true;
            }
            if (dependent.estudo.intercambio) {
              if (!dependent.estudo.intercambio_tempo) return true;
              if (!dependent.estudo.intercambio_valor) return true;
            }
            if (dependent.estudo.possui_seguro_educacional) {
              if (!dependent.estudo.tempo_seguro_educacional) return true;
            }

            return false;
          })
        )
          return false;
      }
      if (pessoa.pessoa_plano.planob_possui_dependente_alem_filhos) {
        if (
          pessoa.dependentes_plano.some((dependentPlan) => {
            if (!dependentPlan.grau_parentesco) return true;
            if (!dependentPlan.grau_parentesco_customizado) return true;
            if (!dependentPlan.renda_mensal_necessaria) return true;
            if (!dependentPlan.quanto_tempo_protecao) return true;

            return false;
          })
        )
          return false;
      }
      if (!pessoa.pessoa_observacao) return false;

      return true;
    },
    completedIncome() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.pessoa_renda.pessoa_renda_salario_bruto) return false;
      if (!pessoa.pessoa_renda.pessoa_renda_salario_liquido) return false;
      if (pessoa.pessoa_renda.pessoa_renda_possui_renda_mensal) {
        if (
          pessoa.pessoa_renda.pessoa_renda_mensais.some((monthlyIncome) => {
            if (!monthlyIncome.nome) return true;
            if (!monthlyIncome.valor) return true;

            return false;
          })
        )
          return false;
      }
      if (pessoa.pessoa_renda.pessoa_renda_possui_renda_anual) {
        if (
          pessoa.pessoa_renda.pessoa_renda_anuais.some((annualIncome) => {
            if (!annualIncome.nome) return true;
            if (!annualIncome.valor) return true;
            if (!annualIncome.periodicidade) return true;

            return false;
          })
        )
          return false;
      }
      if (pessoa.pessoa_renda.valor_rendimentos_tributaveis) return false;
      if (pessoa.pessoa_despesa.media_poupanca_total) return false;
      if (pessoa.pessoa_renda.pessoa_renda_observacao) return false;

      return true;
    },
    completedEquityAndInvestments() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.patrimonio_investimento.emprest_imovel_garantia) return false;
      if (
        pessoa.patrimonio_investimento.patrimonio_itens.some((patrimony) => {
          if (!patrimony.nome) return true;
          if (!patrimony.tipo) return true;
          if (!patrimony.valor) return true;

          return false;
        })
      )
        return false;
      if (!pessoa.patrimonio_investimento.perfil_investidor) return false;
      if (
        pessoa.patrimonio_investimento.investimento_itens.some(
          (investiment) => {
            if (!investiment.nome) return true;
            if (!investiment.tipo) return true;
            if (!investiment.valor) return true;
            if (!investiment.rentabilidade) return true;
            if (!investiment.liquidez) return true;
            if (!investiment.frequencia_aportes) return true;

            return false;
          }
        )
      )
        return false;
      if (!pessoa.patrimonio_investimento.observacao) return false;

      return true;
    },
    completedExpense() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.pessoa_despesa.despesa_fixa_mensal) return false;
      if (
        pessoa.patrimonio_investimento.patrimonio_itens.some((expense) => {
          if (!expense.nome) return true;
          if (!expense.despesa_forma_construcao) return true;
          if (!expense.quitado) return true;
          if (!expense.despesa_operadora) return true;
          if (!expense.valor_mensal) return true;
          if (!expense.despesa_saldo_pagar_financeamento) return true;
          if (!expense.despesa_parcelas_restantes) return true;
          if (!expense.despesa_taxa_juros_financeamento) return true;
          if (!expense.despesa_indice_correcao) return true;

          return false;
        })
      )
        return false;

      if (!pessoa.pessoa_despesa.tipo_declaracao_ir) return false;
      if (!pessoa.pessoa_despesa.contribuicao_inss) return false;
      if (!pessoa.pessoa_despesa.quantos_dependentes) return false;
      if (!pessoa.pessoa_despesa.dependentes) return false;
      if (!pessoa.pessoa_despesa.quantos_alimentando) return false;
      if (!pessoa.pessoa_despesa.despesas_educacao) return false;
      if (!pessoa.pessoa_despesa.despesas_medicas) return false;
      if (!pessoa.pessoa_despesa.pensao_alimenticia) return false;
      if (!pessoa.pessoa_despesa.pgbl_fapi_funpresp) return false;
      if (!pessoa.pessoa_despesa.livro_caixa) return false;
      if (!pessoa.pessoa_despesa.outras_despesas) return false;
      if (!pessoa.pessoa_despesa.ir_retido_deducoes) return false;
      if (!pessoa.pessoa_despesa.divida_curto_prazo) return false;
      if (pessoa.pessoa_despesa.possui_divida_medio_prazo) {
        if (!pessoa.pessoa_despesa.divida_medio_prazo) return false;
      }
      if (pessoa.pessoa_despesa.possui_divida_longo_prazo) {
        if (!pessoa.pessoa_despesa.divida_longo_prazo) return false;
      }
      if (
        !pessoa.pessoa_despesa.contribuicao_cliente &&
        !pessoa.pessoa_despesa.contribuicao_conjuge &&
        !pessoa.pessoa_despesa.outro_contribuinte
      )
        return false;
      if (!pessoa.pessoa_despesa.observacao) return false;

      return true;
    },
    completedPlansAndDreams() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (pessoa.pessoa_possui_sonho) {
        if (
          pessoa.sonhos.some((dream) => {
            if (!dream.nome) return true;
            if (!dream.tipo) return true;
            if (!dream.valor) return true;
            if (!dream.para_quando) return true;
            if (!dream.valor_construido) return true;

            return false;
          })
        )
          return false;
      }
      if (!pessoa.pessoa_plano.plano_observacao) return false;

      return true;
    },
    completedFinancialFreedom() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.pessoa_plano.plano_renda_desejada_liberdade_financeira)
        return false;
      if (!pessoa.pessoa_plano.plano_quantos_anos_liberdade_financeira)
        return false;
      if (!pessoa.pessoa_plano.plano_ate_quantos_anos_liberdade_financeira)
        return false;
      if (!pessoa.pessoa_previdencia_privada.valor_investimento_mensal)
        return false;
      if (pessoa.pessoa_previdencia_privada.possui_investimento) {
        if (
          pessoa.pessoa_previdencia_privada.previdencia_fundos.some(
            (pensionFund) => {
              if (!pensionFund.nome) return true;
              if (!pensionFund.cnpj) return true;
              if (!pensionFund.tipo_plano) return true;
              if (!pensionFund.tributacao) return true;
              if (!pensionFund.valor_atual) return true;

              return false;
            }
          )
        )
          return false;
      }
      if (!pessoa.pessoa_despesa.expectativa_renda_aposentadoria) return false;
      if (!pessoa.pessoa_previdencia_privada.observacao) return false;

      return true;
    },
    completedHealthExpenditureRisk() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (!pessoa.pessoa_saude.altura) return false;
      if (!pessoa.pessoa_saude.peso) return false;
      if (!pessoa.pessoa_saude.fumante) return false;
      if (!pessoa.pessoa_saude.ano_parou_fumar) return false;
      if (!pessoa.pessoa_saude.ultima_consulta) return false;
      if (pessoa.pessoa_saude.toma_remedio_controlado) {
        if (
          pessoa.pessoa_remedios.some((remedio) => {
            if (!remedio.nome) return true;
            if (!remedio.dosagem) return true;

            return false;
          })
        )
          return false;
      }
      if (pessoa.pessoa_saude.possui_previsao_cirurgia) {
        if (!pessoa.pessoa_saude.previsao_cirurgia) return false;
      }
      if (pessoa.pessoa_saude.possui_historico_doenca_familia) {
        if (!pessoa.pessoa_saude.historico_doenca_familia) return false;
      }
      if (pessoa.pessoa_saude.possui_historico_doenca_preexistente) {
        if (!pessoa.pessoa_saude.historico_doenca_preexistente) return false;
      }
      if (pessoa.pessoa_saude.possui_exame_anormal) {
        if (!pessoa.pessoa_saude.exame_anormal) return false;
      }
      if (pessoa.saude.tipo_pessoa) return false;
      if (pessoa.saude.operadora) return false;
      if (pessoa.saude.nome_produto) return false;
      if (pessoa.saude.valor_mensal) return false;
      if (pessoa.saude.tipo_cobertura) return false;
      if (pessoa.saude.valor_reembolso) return false;
      if (pessoa.saude.possui_preferencia) return false;
      if (pessoa.saude.acomodacao) return false;
      if (pessoa.saude.estado) return false;
      if (pessoa.saude.cidade) return false;
      if (['C', 'UE'].includes(pessoa.pessoa_estado_civil)) {
        if (!pessoa.pessoa_conjuge.nome_conjuge) return false;
        if (pessoa.pessoa_conjuge.saude.incluso_valor_titular) {
          if (!pessoa.pessoa_conjuge.saude.operadora) return false;
          if (!pessoa.pessoa_conjuge.saude.nome_produto) return false;
          if (!pessoa.pessoa_conjuge.saude.valor_mensal) return false;
          if (!pessoa.pessoa_conjuge.saude.tipo_cobertura) return false;
          if (!pessoa.pessoa_conjuge.saude.valor_reembolso) return false;
          if (!pessoa.pessoa_conjuge.saude.possui_preferencia) return false;
          if (!pessoa.pessoa_conjuge.saude.acomodacao) return false;
          if (!pessoa.pessoa_conjuge.saude.estado) return false;
          if (!pessoa.pessoa_conjuge.saude.cidade) return false;
        }
      }
      if (
        pessoa.dependentes.some((dependent) => {
          if (!dependent.nome_dependente) return true;
          if (
            !dependent.saude.incluso_valor_titular &&
            !dependent.saude.incluso_valor_conjuge
          ) {
            if (!dependent.saude.tipo_pessoa) return true;
            if (!dependent.saude.operadora) return true;
            if (!dependent.saude.nome_produto) return true;
            if (!dependent.saude.valor_mensal) return true;
            if (!dependent.saude.tipo_cobertura) return true;
            if (!dependent.saude.valor_reembolso) return true;
            if (!dependent.saude.possui_preferencia) return true;
            if (!dependent.saude.acomodacao) return true;
            if (!dependent.saude.estado) return true;
            if (!dependent.saude.cidade) return true;
          }

          return false;
        })
      )
        return false;
      if (!pessoa.pessoa_saude.contato_razao_social) return false;
      if (!pessoa.pessoa_saude.contato_cnpj) return false;
      if (!pessoa.pessoa_saude.contato_representante) return false;
      if (!pessoa.pessoa_saude.contato_telefone) return false;
      if (!pessoa.pessoa_saude.contato_email) return false;
      if (!pessoa.pessoa_saude.observacao) return false;

      return true;
    },
    completedPlanB() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (pessoa.pessoa_plano.planob_possui_rotina_exposicao_risco) {
        if (!pessoa.pessoa_plano.planob_rotina_exposicao_risco) return false;
      }
      if (pessoa.pessoa_plano.planob_possui_motocicleta) {
        if (!pessoa.pessoa_plano.planob_motocicleta_cilindrada) return false;
        if (!pessoa.pessoa_plano.planob_moticicleta_finalidade) return false;
      }
      if (pessoa.pessoa_plano.planob_possui_seguro_vida) {
        if (
          pessoa.seguro_itens.some((safeItem) => {
            if (!safeItem.seguradora) return true;
            if (!safeItem.tipo_seguro) return true;
            if (!safeItem.valor_mensal) return true;
            if (!safeItem.valor_cobertura) return true;
            if (!safeItem.tipo_pessoa) return true;

            return false;
          })
        )
          return false;
      }
      if (
        pessoa.grupo_seguros.some((safeGroup) =>
          safeGroup.grupo_seguro_itens.some((safeGroupItem) => {
            if (!safeGroupItem.seguradora) return true;
            if (!safeGroupItem.tipo_seguro) return true;
            if (!safeGroupItem.valor_mensal) return true;
            if (!safeGroupItem.valor_cobertura) return true;
            if (!safeGroupItem.tipo_pessoa) return true;

            return false;
          })
        )
      )
        return false;
      if (!pessoa.pessoa_plano.planob_observacao) return false;

      return true;
    },
    completedPriorities() {
      if (!this.customer) return false;

      const pessoa = this.customer.pessoa;

      if (
        pessoa.prioridades.some((priority) => {
          if (!priority.tipo) return true;
          if (!priority.nome_personalizado) return true;
          if (!priority.nota) return true;

          return false;
        })
      )
        return false;
      if (
        pessoa.indicacoes.some((indication) => {
          if (!indication.nome) return true;
          if (!indication.email) return true;
          if (!indication.telefone) return true;
          if (!indication.numero_dependentes) return true;

          return false;
        })
      )
        return false;
      if (!pessoa.observacao_prioridades) return false;

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: 'Browse';
}

.div-icon-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check-icon-container {
  position: absolute;
  width: 5px;
  align-self: flex-start;

  top: -13px;
  right: -15px;
}

.check-icon {
  position: absolute;
  top: 1%;
  right: 1%;
  font-size: 1.3rem;
  color: #e9e9f2;
}

.nav-pills .tab-product .nav-link:not(.active) {
  background-color: #e9e9f2 !important;

  .check-icon {
    color: #29b059;
  }
}
</style>
